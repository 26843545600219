import {FC, useEffect, useState} from "react";
import {Dropdown, Menu, MenuButton, MenuItem} from "@mui/joy";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {useAppDispatch, useAppSelector} from "../Redux/Hooks";
import {saveRestaurant} from "../Slices/Restaurant/RestaurantSlice";
import {getLanguageName, LanguageCode, languages} from "../Models/LanguageCode";

const LanguagePicker: FC = () => {
    const user = useAppSelector((state) => state.user).user;
    const restaurant = useAppSelector((state) => state.restaurant).data;
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(user?.locale ?? "tr");

    const dispatch = useAppDispatch();

    useEffect(() => {
        setSelectedLanguage(user?.locale ?? "tr");
    }, [user?.locale]);

    const handleSelect = (language: LanguageCode) => {
        setSelectedLanguage(language);
        if (restaurant) {
            dispatch(saveRestaurant({...restaurant, language}));
        }
    }

    return (
        <Dropdown>
            <MenuButton variant="soft" endDecorator={<ArrowDropDown />}>{getLanguageName(selectedLanguage)}</MenuButton>
            <Menu sx={{maxHeight: "70vh", overflow: "auto"}}>
                {languages.map((language, index) => {
                    return (
                        <MenuItem
                            key={index}
                            selected={selectedLanguage === language}
                            onClick={() => {
                                handleSelect(language);
                            }}
                        >
                            {getLanguageName(language)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </Dropdown>
    );
}

export default LanguagePicker;
