import {FC, useState} from "react";
import {Stack, Table} from "@mui/joy";
import Pagination from "../../../Components/Pagination";
import {ProductType} from "../../../Models/Product/ProductType";
import {RootState} from "../../../Redux/Store";
import {useAppSelector} from "../../../Redux/Hooks";
import ProductsTableHeader from "./ProductsTableHeader";
import ProductRow from "./ProductRow";
import Button from "@mui/joy/Button";
import {SxProps} from "@mui/joy/styles/types";
import useProductsTableHooks from "./useProductsTableHooks";
import InfoModal from "../../../Components/InfoModal";

interface Props {
    onClick: (product: ProductType) => void;
    sx?: SxProps;
}

const ProductsTable: FC<Props> = ({onClick, sx}) => {
    const [handleAllProductSelection, handleProductSelection, selectPage, deleteSelected, isProductSelected] = useProductsTableHooks();

    const productState = useAppSelector((state: RootState) => state.product);
    const selectedProducts = productState.selectedData;
    const products = productState.dataOnCurrentPage;
    const pageInfo = productState.pageInfo;
    const selectedCount = selectedProducts.length;
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const handleProductClick = (product: ProductType) => {
        onClick(product);
    }

    const handleDeleteConfirmation = () => {
        setShowDeleteModal(false);
        deleteSelected();
    }

    return (
        <Stack justifyContent="space-between" sx={{...sx, height: "100%"}}>
            <Stack sx={{p: 1}}>
                <Table hoverRow>
                    <ProductsTableHeader
                        selectionDisabled={selectedCount === 0 && products.length === 0}
                        selectionChecked={products.length === selectedCount && selectedCount > 0}
                        onSelectionChange={handleAllProductSelection}/>
                    <tbody>
                    {products.map(product => {
                        return <ProductRow
                            key={product.id}
                            product={product}
                            isSelected={isProductSelected(product)}
                            onClick={() => handleProductClick(product)}
                            onSelectChange={e => handleProductSelection(e, product)}
                        />
                    })}
                    </tbody>
                </Table>
                <Pagination count={pageInfo.total} currentPage={pageInfo.currentPage} setPage={selectPage}/>
            </Stack>
            {selectedCount > 0 && (
                <Stack direction="row" alignItems="center" justifyContent={"space-between"}
                       sx={{p: 1, backgroundColor: "background.level1"}}>
                    Selected: {selectedCount}
                    <Button color="danger" onClick={() => setShowDeleteModal(true)}>
                        Delete
                    </Button>
                </Stack>
            )}
            <InfoModal
                open={showDeleteModal}
                title="Are you sure to delete?"
                buttonTitle="Delete"
                onButtonClick={handleDeleteConfirmation}
                onClose={() => setShowDeleteModal(false)}
            />
        </Stack>
    );
}

export default ProductsTable;
