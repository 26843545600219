import {useAppDispatch} from "../../../Redux/Hooks";
import {getCategories, saveProduct, setCategories, setData, updateData} from "../../../Slices/Product/EditProductSlice";
import {CategoryType} from "../../../Models/Category/CategoryType";
import {ProductType} from "../../../Models/Product/ProductType";

export default () => {
    const dispatch = useAppDispatch();

    const handleData = (product: ProductType | null) => {
        dispatch(setData(product));
        if (product) {
            dispatch(getCategories());
        }
    }

    const save = () =>{
        dispatch(saveProduct());
    }

    const handleCategoriesChange = (data: CategoryType[]) => {
        dispatch(setCategories(data));
    }

    const handleProductChange = (product: ProductType) => {
        dispatch(updateData(product));
    }

    return [handleData, save, handleCategoriesChange, handleProductChange] as const;
}
