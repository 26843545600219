import {FC} from "react";
import {Checkbox, Stack, Typography} from "@mui/joy";
import ImageButton from "../../../Components/ImageButton";
import {CategoryType} from "../../../Models/Category/CategoryType";
import SortableRow from "../../../Components/SortableRow";
import {DragIndicator} from "@mui/icons-material";

interface Props {
    isEditing?: boolean;
    category: CategoryType;
    isSelected: boolean;
    onClick: (e: any) => void;
    onSelectChange: (e: any) => void;
}

const CategoryRow: FC<Props> = ({isEditing, category, isSelected, onClick, onSelectChange}) => {
    return (
        <SortableRow id={category.id} onClick={onClick}>
            {isEditing && (
                <td>
                    <DragIndicator/>
                </td>
            )}
            <td>
                <Checkbox
                    checked={isSelected}
                    onChange={onSelectChange}
                    onClick={e => e.stopPropagation()}
                />
            </td>
            <td valign='middle'>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}>
                    <ImageButton src={category.image.src} onClick={() => {
                    }}/>
                    <Typography level="body-xs">{category.name}</Typography>
                </Stack>
            </td>
            <td>
                <Typography level="body-xs">{category.description}</Typography>
            </td>
        </SortableRow>
    )
}

export default CategoryRow;
