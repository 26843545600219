import {FC} from "react";
import {ListItem, ListItemContent, ListItemDecorator, Stack, Typography} from "@mui/joy";
import {DragIndicator, InsertPhoto} from "@mui/icons-material";
import ListDivider from "@mui/joy/ListDivider";
import SortableRow from "./SortableRow";
import {ProductType} from "../Models/Product/ProductType";
import SortableItem from "./SortableItem";

interface Props {
    product: ProductType;
}

const ProductListRow: FC<Props> = ({product}) => {
    return (
        <SortableItem id={product.id} onClick={() => {}}>
            <ListItem>
                <ListItemDecorator>
                    <DragIndicator/>
                </ListItemDecorator>
                <ListItemContent>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Stack alignItems="center" justifyContent="center" style={{width: "50px", height: "50px", borderRadius: 6, overflow: "hidden"}}>
                            {product.image.src ? <img src={product.image.src} alt='' style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                padding: 0
                            }}/> : <InsertPhoto style={{width: '50%', height: '50%'}}/>}
                        </Stack>
                        <Typography level="body-md">
                            {product.name}
                        </Typography>
                    </Stack>
                </ListItemContent>
            </ListItem>
        </SortableItem>
    )
}

export default ProductListRow;
