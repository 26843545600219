import {FC, ReactNode} from "react";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";

interface Props {
    disabled?: boolean;
    items: (UniqueIdentifier | {
        id: UniqueIdentifier;
    })[];
    handleDragEnd: (event: any) => void;
    children?: ReactNode;
}

const SortableTableContext: FC<Props> = ({disabled, items, handleDragEnd, children}) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                disabled={disabled}
                items={items}
                strategy={verticalListSortingStrategy}
            >
                { children }
            </SortableContext>
        </DndContext>
    )
}

export default SortableTableContext;
