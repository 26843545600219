import {extendTheme, Theme} from "@mui/joy/styles";

export interface MyTheme extends Theme {
    direction: 'ltr' | 'rtl';
}

const commonPalette = {
    "primary": {
        "50": "#e4ebf3",
        "100": "#dae6f1",
        "200": "#bfd6ed",
        "300": "#91bbe7",
        "400": "#408ddb",
        "500": "#1E5DC3",
        "600": "#175a9e",
        "700": "#114376",
        "800": "#0a2541",
        "900": "#051322"
    },
    "neutral": {
        "50": "#f1f2f4",
        "100": "#e7eaee",
        "200": "#d4dee5",
        "300": "#c5cfd8",
        "400": "#999fa6",
        "500": "#5f676f",
        "600": "#525a64",
        "700": "#30363c",
        "800": "#16191b",
        "900": "#0b0c0d"
    },
    "danger": {
        "50": "#f4ecec",
        "100": "#f2dbdb",
        "200": "#edbdbd",
        "300": "#e79292",
        "400": "#db6f6f",
        "500": "#bc1b1b",
        "600": "#9e1717",
        "700": "#781111",
        "800": "#400a0a",
        "900": "#230505"
    },
    "success": {
        "50": "#ecf4ec",
        "100": "#daf1da",
        "200": "#bfedbf",
        "300": "#9bdf9b",
        "400": "#4eb54e",
        "500": "#1e751e",
        "600": "#126812",
        "700": "#0a440a",
        "800": "#042d04",
        "900": "#021c02"
    },
    "warning": {
        "50": "#f4f0ec",
        "100": "#f3e7d8",
        "200": "#f2d8ba",
        "300": "#e9c090",
        "400": "#e1943c",
        "500": "#945712",
        "600": "#6e400c",
        "700": "#462908",
        "800": "#2c1a05",
        "900": "#1c0f02"
    }
}

const myTheme: MyTheme = extendTheme({
    direction: 'ltr',
    "colorSchemes": {
        "light": {
            "palette": commonPalette
        },
        "dark": {
            "palette": commonPalette
        }
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: ({ theme}) => ({
                    ...((theme as MyTheme).direction === 'rtl' && {
                        transform: 'scaleX(-1)',
                    })
                })
            }
        },
        JoyTable: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    },
                }
            }
        },
        JoyRadio: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                },
                label: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        },
        JoyButton: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        },
        JoyMenuButton: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        },
        JoyMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        },
        JoySelect: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        },
        JoyOption: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        },
        JoyInput: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        },
        JoyListItem: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    '@media (min-width:600px)': {
                        fontSize: "1rem",
                    }
                }
            }
        }
    },
    typography: {
        h1: {
            fontSize: "2.5rem",
            '@media (min-width:600px)': {
                fontSize: "3rem",
            }
        },
        h2: {
            fontSize: "1.8rem",
            '@media (min-width:600px)': {
                fontSize: "2rem",
            }
        },
        h3: {
            fontSize: "1.2rem",
            '@media (min-width:600px)': {
                fontSize: "1.5rem",
            }
        },
        h4: {
            fontSize: "1rem",
            '@media (min-width:600px)': {
                fontSize: "1.2rem",
            }
        },
        'title-lg': {
            fontSize: "1.5rem",
            '@media (min-width:600px)': {
                fontSize: "2rem",
            }
        },
        'title-md': {
            fontSize: "1.2rem",
            '@media (min-width:600px)': {
                fontSize: "1.5rem",
            }
        },
        'title-sm': {
            fontSize: "1rem",
            '@media (min-width:600px)': {
                fontSize: "1.2rem",
            }
        },
        'title-xs': {
            fontSize: "0.8rem",
            '@media (min-width:600px)': {
                fontSize: "1rem",
            }
        },
        'body-lg': {
            fontSize: "1.5rem",
            '@media (min-width:600px)': {
                fontSize: "2rem",
            }
        },
        'body-md': {
            fontSize: "1.2rem",
            '@media (min-width:600px)': {
                fontSize: "1.5rem",
            }
        },
        'body-sm': {
            fontSize: "1rem",
            '@media (min-width:600px)': {
                fontSize: "1.2rem",
            }
        },
        'body-xs': {
            fontSize: "0.8rem",
            '@media (min-width:600px)': {
                fontSize: "1rem",
            }
        },
    }
}) as MyTheme;

export default myTheme;

