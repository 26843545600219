import {Category, Fastfood, Home, ManageAccounts, Settings, SvgIconComponent} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";

export type NavigationItemKey = "home" | "categories" | "products" | "profile" | "options";

export type NavigationItem = {
    key: NavigationItemKey;
    icon: SvgIconComponent;
}

export const homeNavigationItem: NavigationItem = {
    key: "home",
    icon: Home,
};

export const productsNavigationItem: NavigationItem = {
    key: "products",
    icon: Fastfood,
};

export const categoriesNavigationItem: NavigationItem = {
    key: "categories",
    icon: Category,
};

export const profileNavigationItem: NavigationItem = {
    key: "profile",
    icon: ManageAccounts,
};

export const optionsNavigationItem: NavigationItem = {
    key: "options",
    icon: Settings,
};

export const getNavigationItemTitle = (key: NavigationItemKey, t: TFunction) => {
    switch (key) {
        case "home":
            return t("homePage.title")
        case "categories":
            return t("categoriesPage.title")
        case "products":
            return t("productsPage.title")
        case "profile":
            return t("restaurantProfilePage.title")
        case "options":
            return t("settingsPage.title")
    }
}

export const items: NavigationItem[] = [
    homeNavigationItem,
    categoriesNavigationItem,
    productsNavigationItem,
    profileNavigationItem,
    optionsNavigationItem,
];
