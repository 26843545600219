import {CategoryType, createEmptyCategory} from "../../Models/Category/CategoryType";
import {ProductType} from "../../Models/Product/ProductType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {arrayMove} from "@dnd-kit/sortable";
import {CategoryApi} from "../../Api/Category";
import {RootState} from "../../Redux/Store";

type EditCategoryState = {
    category: CategoryType;
    products: ProductType[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    savingStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | undefined;
}

const initialState: EditCategoryState = {
    category: createEmptyCategory(),
    products: [],
    status: 'idle',
    savingStatus: 'idle',
    error: undefined,
}

export const saveCategory = createAsyncThunk(
    'editCategory/save',
    async (_, {getState}) => {
        const state= (getState() as RootState).editCategory;
        const productIds = state.products.map(product => product.id);
        return await CategoryApi.save(state.category, productIds);
    }
);

export const getProducts = createAsyncThunk(
    'editCategory/getProducts',
    async (categoryId: string, { getState }) => {
        const state = getState() as RootState;
        return await CategoryApi.getProducts(categoryId, state);
    }
);

const editCategorySlice = createSlice({
    name: 'editCategory',
    initialState: initialState,
    reducers: {
        setData: (state, action: {payload: CategoryType | null, type: string}) => {
            state.status = 'idle';
            state.error = undefined;
            state.savingStatus = 'idle';
            if (action.payload) {
                state.category = action.payload;
            } else {
                state.category = createEmptyCategory();
                state.products = [];
            }
        },
        updateData: (state, action: {payload: CategoryType, type: string}) => {
            state.category = {
                ...state.category,
                ...action.payload,
            }
        },
        setProducts: (state, action: {payload: ProductType[], type: string}) => {
            state.products = action.payload;
        },
        updateProducts: (state, action: {payload: {oldIndex: number, newIndex: number}, type: string}) => {
            state.products = arrayMove(state.products, action.payload.oldIndex, action.payload.newIndex);
        },
        reset: (state) => {
            state.category = createEmptyCategory();
            state.products = [];
            state.status = 'idle';
            state.error = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(saveCategory.pending, state => {
                state.savingStatus = 'loading';
            })
            .addCase(saveCategory.fulfilled, (state, action) => {
                state.savingStatus = 'succeeded';
                state.category = action.payload.category;
            })
            .addCase(saveCategory.rejected, (state, action) => {
                state.savingStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(getProducts.pending, state => {
                state.status = 'loading';
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const {setData, updateData, setProducts, updateProducts, reset} = editCategorySlice.actions;
export default editCategorySlice.reducer;
