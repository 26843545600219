import {FC, useEffect, useState} from "react";
import {
    IconButton,
    List,
    Sheet,
    Stack,
    Typography, useTheme
} from "@mui/joy";
import {Close} from "@mui/icons-material";
import Button from "@mui/joy/Button";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import SortableTableContext from "../../Components/SortableTableContext";
import ProductListRow from "../../Components/ProductListRow";
import type {DragEndEvent} from "@dnd-kit/core/dist/types";
import {updateProducts} from "../../Slices/Category/EditCategorySlice";
import {arrayMove} from "@dnd-kit/sortable";
import {ProductType} from "../../Models/Product/ProductType";
import ListDivider from "@mui/joy/ListDivider";
import useMyTheme from "../../Theme/useMyTheme";
import {useTranslation} from "react-i18next";

interface Props {
    onClose?: () => void;
}

type IndexChange = {
    oldIndex: number;
    newIndex: number;
}

const RearrangeProductsView: FC<Props> = ({onClose}) => {
    const editCategoryState = useAppSelector(state => state.editCategory);
    const products = editCategoryState.products;
    const [productsCopy, setProductsCopy] = useState<ProductType[]>(products);
    const dispatch = useAppDispatch();
    const [indexChange, setIndexChange] = useState<IndexChange | null>(null);
    const { myTheme } = useMyTheme();
    const {t} = useTranslation();;

    useEffect(() => {
        setProductsCopy(products);
    }, [products]);

    const handleSubmit = (e: any) => {
        if (indexChange) {
            dispatch(updateProducts(indexChange));
        }
        onClose && onClose();
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            setProductsCopy(productsToCopy => {
                const oldIndex = productsToCopy.findIndex((item) => item.id === active.id);
                const newIndex = productsToCopy.findIndex((item) => item.id === over.id);
                setIndexChange({oldIndex, newIndex});
                return arrayMove(productsToCopy, oldIndex, newIndex)
            });
        }
    }

    return (
        <Sheet dir={myTheme.direction} sx={{minWidth: "40vw"}}>
            <Stack spacing={4}>
                <Stack direction="row" justifyContent="space-between">
                    <IconButton onClick={onClose}>
                        <Close/>
                    </IconButton>
                    <Button type="button" onClick={handleSubmit}>{t("save")}</Button>
                </Stack>
                <Typography level="h3">{t("productsPage.editProducts")}</Typography>
                <SortableTableContext items={productsCopy} handleDragEnd={handleDragEnd}>
                    <List>
                        {productsCopy.map((product, index) => (
                            <div key={product.id}>
                                <ProductListRow product={product}/>
                                <ListDivider
                                    sx={{"--Divider-lineColor": index === productsCopy.length - 1 ? "transparent" : "var(--joy-palette-divider)"}}
                                />
                            </div>
                        ))}
                    </List>
                </SortableTableContext>
            </Stack>
        </Sheet>
    );
}

export default RearrangeProductsView;
