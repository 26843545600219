import {FC} from "react";
import {Sheet, Table, Typography} from "@mui/joy";
import {useAppSelector} from "../../Redux/Hooks";
import {formatPrice} from "../../Models/Currency";
import {ProductWithAdjustedPrice} from "../../Models/Product/ProductWithAdjustedPrice";
import {useTranslation} from "react-i18next";

interface Props {
    products: ProductWithAdjustedPrice[];
}

const AdjustProductPriceTable: FC<Props> = ({products}) => {
    const currency = useAppSelector(state => state.restaurant).data?.currency ?? "TRY";
    const {t} = useTranslation();

    return (
        <Sheet variant="soft" sx={{borderRadius: 'sm' }}>
            <Table
                sx={{
                    "--TableCell-height": "28px"
                }}
            >
                <thead>
                <tr>
                    <th>
                        <Typography level="title-xs">{t("productsPage.productName")}</Typography></th>
                    <th>
                        <Typography level="title-xs">{t("productsPage.adjustPrices.originalPrice")}</Typography>
                    </th>
                    <th>
                        <Typography level="title-xs">{t("productsPage.adjustPrices.newPrice")}</Typography>
                    </th>
                </tr>
                </thead>
                <tbody>
                {products.map(product => (
                    <tr key={product.id}>
                        <td scope="row">
                            <Typography level="body-xs">{product.name}</Typography>
                        </td>
                        <td>
                            <Typography level="body-xs">{formatPrice(product.originalPrice, currency)}</Typography>
                        </td>
                        <td>
                            <Typography level="body-xs">{formatPrice(product.adjustedPrice, currency)}</Typography>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Sheet>
    )
}

export default AdjustProductPriceTable
