import {createPageInfo} from "../../Models/PageInfo";
import {CategoryState} from "./CategoryState";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CategoryType} from "../../Models/Category/CategoryType";
import {
    deleteData,
    filterData,
    handleDataSelectionChange,
    saveData,
    setDataOnCurrentPage,
} from "../../Models/DataState";
import {getRestaurant} from "../Restaurant/RestaurantSlice";
import {saveCategory} from "./EditCategorySlice";
import {RootState} from "../../Redux/Store";
import {CategoryApi} from "../../Api/Category";
import {arrayMove} from "@dnd-kit/sortable";

const initialState: CategoryState = {
    data: [],
    filteredData: [],
    dataOnCurrentPage: [],
    selectedData: [],
    pageInfo: createPageInfo(),
    status: 'idle',
    filters: null,
    error: undefined,
}

export const deleteSelectedCategories = createAsyncThunk(
    'category/deleteCategories',
    async (_, {getState}) => {
        const state = getState() as RootState;
        const categoryIds = state.category.selectedData.map(category => category.id);
        await CategoryApi.deleteCategories(categoryIds);
    },
);

export const updateIndexes = createAsyncThunk(
    'category/updateCategoryIndexes',
    async (arg: {oldIndex: number, newIndex: number}, { getState }) => {
        const state = getState() as RootState;
        const categoryState = state.category;
        categoryState.data = arrayMove(categoryState.data, arg.oldIndex, arg.newIndex);
        const categoryIds = categoryState.data.map(category => category.id);
        await CategoryApi.saveIndexes(categoryIds);
        const data: CategoryType[] = [];
        categoryState.data.forEach((category, index) => {
            const copiedCategory = {...category};
            copiedCategory.index = index;
            data.push(copiedCategory);
        });
        return data;
    },
);

const categorySlice = createSlice({
    name: 'category',
    initialState: initialState,
    reducers: {
        search: (state, action: {payload: string, type: string}) => {
            state.filters = {
                ...state.filters,
                searchTerm: action.payload,
            }
            filterData(state);
        },
        changePage: (state, action: {payload: number, type: string}) => {
            state.pageInfo.currentPage = action.payload;
            setDataOnCurrentPage(state);
        },
        handleSelectionChange: (state, action: {payload: {categories: CategoryType[], isSelected: boolean}, type: string}) => {
            handleDataSelectionChange(action.payload.categories, action.payload.isSelected, state);
        },
        resetStatus: (state) => {
            state.error = undefined;
            state.status = 'idle';
        },
        resetFilters: (state) => {
            state.filters = null;
            filterData(state, true);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(deleteSelectedCategories.pending, state => {
                state.status = 'loading';
            })
            .addCase(deleteSelectedCategories.fulfilled, (state) => {
                state.status = 'succeeded';
                deleteData(state.selectedData, state);
            })
            .addCase(deleteSelectedCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateIndexes.pending, state => {
                state.status = 'loading';
            })
            .addCase(updateIndexes.fulfilled, (state, action) => {
                state.data = action.payload;
                filterData(state, false);
            })
            .addCase(updateIndexes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getRestaurant.fulfilled, (state, action) => {
                state.data = action.payload.categories;
                state.filters = null;
                filterData(state);
            })
            .addCase(saveCategory.fulfilled, (state, action) => {
                saveData(action.payload.category, state);
                state.status = "succeeded"
            })
    }
});

export const {
    search,
    changePage,
    handleSelectionChange,
    resetStatus,
    resetFilters,
} = categorySlice.actions;
export default categorySlice.reducer;
