import {FC, useEffect, useState} from "react";
import {IconButton, Stack, Typography} from "@mui/joy";
import Button from "@mui/joy/Button";
import {ArrowBackIos, ArrowForwardIos} from "@mui/icons-material";

interface PaginationProps {
    count: number;
    currentPage: number;
    setPage: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({count, currentPage, setPage}) => {
    const [selectedPage, setSelectedPage] = useState<number>(currentPage);

    useEffect(() => {
        setSelectedPage(currentPage);
    }, [currentPage]);

    const selectPage = (page: number) => {
        setSelectedPage(page);
        setPage(page);
    }

    return (
        <Stack
            direction="row"
            spacing={1}
        >
            <IconButton color="neutral" disabled={selectedPage === 0} onClick={() => selectPage(selectedPage - 1)}>
                <ArrowBackIos />
            </IconButton>
            {Array(count).fill(0).map((_, index) => {
                return <Button key={index} color={selectedPage == index ? "primary" : "neutral"}
                               variant="soft"
                               onClick={() => selectPage(index)}
                >
                    <Typography level="body-xs">{index + 1}</Typography>
                </Button>
            })}
            <IconButton color="neutral"
                        disabled={selectedPage === count - 1}
                        onClick={() => selectPage(selectedPage + 1)}
            >
                <ArrowForwardIos/>
            </IconButton>
        </Stack>
    );
}

export default Pagination;
