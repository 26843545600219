import {RestaurantInfoType} from "../Models/RestaurantInfoType";
import Parse from "parse";
import {
    convertCategories,
    convertCategoryProducts,
    convertProducts,
    convertRestaurant,
    convertUser
} from "./Converters";
import {ThrowHandler} from "../ThrowHandler";
import {RestaurantType} from "../Models/RestaurantType";

const getRestaurantInfo = async () => {
    const result = await ThrowHandler.tryFunction(Parse.Cloud.run("getInfo"));
    const restaurantInfo: RestaurantInfoType = {
        user: convertUser(result.user),
        restaurant: convertRestaurant(result.restaurant),
        categories: convertCategories(result.categories),
        products: convertProducts(result.products),
        categoryProducts: convertCategoryProducts(result.categoryProducts),
    }
    return restaurantInfo;
}

const saveRestaurant = async (restaurant: RestaurantType) => {
    const result = await ThrowHandler.tryFunction(Parse.Cloud.run("saveRestaurant", {restaurant}));
    const updatedRestaurant = convertRestaurant(result);
    return updatedRestaurant;
}

export const restauarantAPI = {getRestaurantInfo, saveRestaurant};
