import React, {ElementType, FC, ReactNode} from "react";
import {Link} from "@mui/joy";
import type { To } from "react-router";
import {DefaultVariantProp, SxProps} from "@mui/joy/styles/types";

interface Props {
    variant?: DefaultVariantProp;
    component: ElementType;
    to?: To;
    sx?: SxProps;
    children?: ReactNode;
}

const LinkButton: FC<Props> = ({variant, component, to, sx, children}) => {
    return (
        <Link variant={variant} underline="none" component={component} to={to} sx={{px: "16px", py: "6px", borderRadius: 'var(--joy-radius-sm)', justifyContent: "center", ...sx}}>
            {children}
        </Link>
    );
}

export default LinkButton;
