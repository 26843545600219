import {FC} from "react";
import {Divider, Radio, RadioGroup, Stack, Table, Typography} from "@mui/joy";
import AdjustPriceTypePicker from "./AdjustPriceTypePicker";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import {
    adjustPrices,
    setAdjustPercent,
    setAdjustPriceType,
    setIsAdjusted,
    setRoundType
} from "../../Slices/AdjustPricesSlice";
import {AdjustPriceType} from "../../Models/AdjustPriceState";
import EqualGridContainer from "../EqualGridContainer";
import {useTranslation} from "react-i18next";

interface AdjustPricesPageProps {
    onClose?: () => void;
}

const AdjustPricePage: FC<AdjustPricesPageProps> = ({onClose}) => {
    const state = useAppSelector(state => state.adjustPrice);
    const adjustPriceType = state.adjustPriceType;
    const roundType = state.roundType;
    const adjustPercent = state.adjustPercent;
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const handleRoundTypeChange = (event: any) => {
        const {value} = event.target;
        dispatch(setRoundType(value));
    }

    const handleAdjustPriceTypeChange = (type: AdjustPriceType) => {
        dispatch(setAdjustPriceType(type));
    }

    const handlePercentChange = (event: any) => {
        const {value} = event.target;
        dispatch(setAdjustPercent(value));
    }

    const handleNextAction = () => {
        dispatch(adjustPrices());
        dispatch(setIsAdjusted(true));
    }

    return (
        <Stack spacing={2}>
            <Typography level="h4">{t("productsPage.adjustPrices.title")}</Typography>
            <Table>
                <tbody>
                <tr>
                    <td style={{ width: '20%' }}>
                        <Typography level="title-xs">{t("productsPage.adjustPrices.change")}</Typography>
                    </td>
                    <td>
                        <EqualGridContainer spacing={1} childrenGrid={{xs: 6}}>
                            <AdjustPriceTypePicker sx={{width: "100%"}} value={adjustPriceType} onChange={handleAdjustPriceTypeChange}/>
                            <Input
                                fullWidth
                                id="adjustPercent"
                                type="number"
                                value={adjustPercent}
                                onChange={handlePercentChange}
                                endDecorator={"%"}
                            />
                        </EqualGridContainer>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Typography level="title-xs">{t("productsPage.adjustPrices.rounding")}</Typography>
                    </td>
                    <td>
                        <RadioGroup defaultValue={roundType}>
                            <Radio value="none" onChange={handleRoundTypeChange} label={t("productsPage.adjustPrices.roundingNone")}></Radio>
                            <Radio value="upwards" onChange={handleRoundTypeChange} label={t("productsPage.adjustPrices.roundingUp", {first: "5,76", second: "6,00"})}></Radio>
                            <Radio value="downwards" onChange={handleRoundTypeChange} label={t("productsPage.adjustPrices.roundingDown", {first: "5,76", second: "5,00"})}></Radio>
                            <Radio value="upwards99" onChange={handleRoundTypeChange} label={t("productsPage.adjustPrices.rounding99", {first: "5,76", second: "5,99"})}></Radio>
                        </RadioGroup>
                    </td>
                </tr>
                </tbody>
            </Table>
            <Divider></Divider>
            <Stack spacing={1} direction="row" justifyContent="end">
                <Button variant="outlined" onClick={onClose}>{t("cancel")}</Button>
                <Button variant="solid" onClick={handleNextAction}>{t("save")}</Button>
            </Stack>
        </Stack>
    )
}

export default AdjustPricePage;
