import {DefaultModel, IndexModel} from "../BaseModel";

export type CategoryType = DefaultModel & IndexModel & {
    description: string | null;
    image: {
        src: string | null;
        isNew: boolean;
    };
}

export const createEmptyCategory = (): CategoryType => {
    return {
        id: "",
        name: "",
        index: 0,
        description: "",
        image: {
            src: "",
            isNew: false,
        },
    };
}
