import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ProductType} from "../../Models/Product/ProductType";
import {createPageInfo} from "../../Models/PageInfo";
import {
    ProductState,
} from "./ProductState";
import {
    createFiltersIfNotExist, deleteData,
    filterData, handleDataSelectionChange, saveData,
    setDataOnCurrentPage, setPageInfo,
} from "../../Models/DataState";
import {getRestaurant} from "../Restaurant/RestaurantSlice";
import {saveProduct} from "./EditProductSlice";
import {CategoryProductType} from "../../Models/CategoryProductType";
import {RootState} from "../../Redux/Store";
import {ProductApi} from "../../Api/Product";
import {updatePrices} from "../AdjustPricesSlice";

const initialState: ProductState = {
    data: [],
    filteredData: [],
    dataOnCurrentPage: [],
    selectedData: [],
    pageInfo: createPageInfo(),
    status: 'idle',
    filters: null,
    error: undefined,
}

export const deleteProducts = createAsyncThunk(
    'product/deleteProducts',
    async (_, {getState}) => {
        const state = getState() as RootState;
        const productIds = state.product.selectedData.map(product => product.id);
        await ProductApi.deleteProducts(productIds);
    },
);

const filterByCategoryId = (
    categoryId: string | null,
    state: ProductState,
    categoryProducts: CategoryProductType[]) => {
    filterData(state);
    if (categoryId == null) {
        delete state.filters?.categoryId;
    } else {
        createFiltersIfNotExist(state);
        state.filters = {
            ...state.filters!,
            categoryId: categoryId,
        }
        const filteredCategoryProducts = categoryProducts.filter(cp => cp.categoryId === categoryId);
        const filteredData = state.filteredData.filter(product => filteredCategoryProducts.find(cp => cp.productId === product.id) !== undefined);
        state.filteredData = filteredData;
        state.selectedData = state.selectedData.filter(p => state.filteredData.find(p2 => p2.id === p.id) !== undefined);
        setPageInfo(state);
        setDataOnCurrentPage(state);
    }
}

const productSlice = createSlice({
    name: 'product',
    initialState: initialState,
    reducers: {
        search: (state, action: {payload: string, type: string}) => {
            state.filters = {
                ...state.filters,
                searchTerm: action.payload,
            }
            filterData(state);
        },
        update: (state, action: {payload: ProductType, type: string}) => {
            saveData(action.payload, state);
        },
        changePage: (state, action: {payload: number, type: string}) => {
            state.pageInfo.currentPage = action.payload;
            setDataOnCurrentPage(state);
        },
        filterByCategory: (state, action: {payload: {categoryId: string | null, categoryProducts: CategoryProductType[]}, type: string}) => {
            filterByCategoryId(action.payload.categoryId, state, action.payload.categoryProducts);
        },
        filterByStockType: (state, action: {payload: boolean | null, type: string}) => {
            if (action.payload == null) {
                delete state.filters?.available;
            } else {
                createFiltersIfNotExist(state);
                state.filters = {
                    ...state.filters!,
                    available: action.payload
                }
            }
            filterData(state);
        },
        handleSelectionChange: (state, action: {payload: {products: ProductType[], isSelected: boolean}, type: string}) => {
            handleDataSelectionChange(action.payload.products, action.payload.isSelected, state);
        },
        resetStatus: (state) => {
            state.error = undefined;
            state.status = 'idle';
        }
    },
    extraReducers: builder => {
        builder
            .addCase(deleteProducts.pending, state => {
                state.status = 'loading';
            })
            .addCase(deleteProducts.fulfilled, (state) => {
                state.status = 'succeeded';
                deleteData(state.selectedData, state);
            })
            .addCase(deleteProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(saveProduct.fulfilled, (state, action) => {
                saveData(action.payload.product, state);
                state.status = 'succeeded';
            })
            .addCase(updatePrices.fulfilled, (state, action) => {
                state.data = action.payload;
                filterData(state);
            })
            .addCase(getRestaurant.fulfilled, (state, action) => {
                state.data = action.payload.products;
                state.filters = null;
                filterData(state);
            })
    }
});

export const {
    search,
    update,
    changePage,
    filterByCategory,
    filterByStockType,
    handleSelectionChange,
    resetStatus
} = productSlice.actions;
export default productSlice.reducer;
