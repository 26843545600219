import {FC, useEffect, useRef, useState} from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import {Checkbox, IconButton, Modal, ModalDialog, Sheet, Stack} from "@mui/joy";
import DataPicker from "../../../Components/DataPicker";
import {CategoryType} from "../../../Models/Category/CategoryType";
import DataPickerButton from "../../../Components/DataPickerButton";
import {Close} from "@mui/icons-material";
import ImagePicker from "../../../Components/ImagePicker";
import {ProductType} from "../../../Models/Product/ProductType";
import {useAppSelector} from "../../../Redux/Hooks";
import useEditProductHooks from "./useEditProductHooks";
import CurrencyInput from "../../../Components/CurrencyInput";
import useMyTheme from "../../../Theme/useMyTheme";
import {useTranslation} from "react-i18next";

type Props = {
    productToEdit: ProductType | null;
    onClose?: () => void;
}

const CreateEditProductPage: FC<Props> = ({productToEdit, onClose}) => {
    const [handleData, save, handleCategoriesChange, handleProductChange] = useEditProductHooks();

    const formRef = useRef<HTMLFormElement>(null);
    const [openPickCategoryDialog, setOpenPickCategoryDialog] = useState<boolean>(false);

    const categoryState = useAppSelector(state => state.category);
    const state = useAppSelector(state => state.editProduct);
    const product = state.product;
    const selectedCategories = state.categories;
    const { myTheme } = useMyTheme();
    const {t} = useTranslation();

    useEffect(() => {
        handleData(productToEdit);
        return () => {
            handleData(null);
        }
    }, []);

    useEffect(() => {
        if (state.savingStatus === "succeeded") {
            onClose && onClose();
        }
    }, [state.savingStatus]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (formRef.current && !formRef.current.reportValidity()) {
            return;
        }
        save();
    }

    const handleOnDoneSelectedCategories = (data: CategoryType[]) => {
        handleCategoriesChange(data);
        setOpenPickCategoryDialog(false);
    }

    const handleValueChange = (e: any) => {
        const {id, value} = e.target;
        const updatedProduct = {...product, [id]: value};
        handleProductChange(updatedProduct);
    }

    const handlePriceChange = (e: any) => {
        const {id, value} = e.target;
        const number = Number(value);
        const updatedProduct = {...product, [id]: number};
        handleProductChange(updatedProduct);
    }
    const handleImageChange = (src: string | null) => {
        const updatedProduct = {...product, image: {src: src, isNew: true}};
        handleProductChange(updatedProduct);
    }

    return (
        <Sheet dir={myTheme.direction} sx={{minWidth: "40vw", overflow: "scroll"}}>
            <form ref={formRef}>
                <Stack spacing={4}>
                    <Stack direction="row" justifyContent="space-between">
                        <IconButton onClick={onClose}>
                            <Close/>
                        </IconButton>
                        <Button type="button" onClick={handleSubmit}>{t("save")}</Button>
                    </Stack>
                    <Input id="name" value={product.name} placeholder={t("productsPage.productName")} required onChange={handleValueChange}/>
                    <Input id="description" value={product.description ?? undefined} placeholder={t("description")} onChange={handleValueChange}/>
                    <CurrencyInput id="price" value={product.price} placeholder={t("price")} onChange={handlePriceChange}/>
                    <Checkbox checked={product.available} label={t("productsPage.stockStatus")} required/>
                    <DataPickerButton title={t("categoriesPage.title")} pickedData={selectedCategories} onClick={() => setOpenPickCategoryDialog(true)}/>
                    <ImagePicker style={{width: '100%'}} src={product.image.src} onFileChange={handleImageChange}/>
                </Stack>
            </form>
            <Modal open={openPickCategoryDialog} onClose={() => setOpenPickCategoryDialog(false)}>
                <ModalDialog>
                    <DataPicker
                        pickedData={selectedCategories}
                        data={categoryState.data} title={t("categoriesPage.title")}
                        onDone={handleOnDoneSelectedCategories}
                        onClose={() => setOpenPickCategoryDialog(false)}
                    />
                </ModalDialog>
            </Modal>
        </Sheet>
    );
}

export default CreateEditProductPage;
