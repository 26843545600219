import {FC, useEffect, useRef} from "react";
import {useAppSelector} from "../Redux/Hooks";
import {Box, Stack} from "@mui/joy";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import LinkButton from "../Components/LinkButton";
import {Link as RouterLink} from "react-router-dom";
import InfoBanner from "../Components/InfoBanner";
import useForgotPasswordPageHooks from "./useForgotPasswordPageHooks";
import {resetStatus} from "../Slices/User/UserSlice";
import {useTranslation} from "react-i18next";

const ForgotPasswordPage: FC = () => {
    const error = useAppSelector(state => state.user).error;
    const formRef = useRef<HTMLFormElement>(null);
    const [email, handleEmailInput, handleRequestPassword, handleResetStatus] = useForgotPasswordPageHooks();
    const {t} = useTranslation();

    useEffect(() => {
        return () => {
            resetStatus();
        }
    }, []);

    const handleBannerClose = () => {
        handleResetStatus();
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (formRef.current && !formRef.current.reportValidity()) {
            return;
        }
        handleRequestPassword();
    }

    return (
        <Stack sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
        }}>
            <Box sx={{width: "50%", heigth: "50%"}}>
                <h1 style={{textAlign: "center"}}>{t("forgotPassword.title")}</h1>
                <form ref={formRef}>
                    <Stack spacing={2}>
                        <Input
                            id="email"
                            value={email}
                            type={"email"}
                            placeholder={t("email")}
                            required
                            onChange={handleEmailInput}
                        />
                        <Button type="button" onClick={handleSubmit}>
                            {t("send")}
                        </Button>
                        <Stack spacing={-0.025} alignItems="center">
                            <LinkButton component={RouterLink} to="/login">
                                {t("login.loginButtonTitle")}
                            </LinkButton>
                        </Stack>
                        <Stack spacing={0.5} alignItems="center">
                            <p style={{margin: 0}}>{t("login.noAccount")}</p>
                            <LinkButton component={RouterLink} to="/register">
                                {t("signUp.signUpButtonTitle")}
                            </LinkButton>
                        </Stack>
                    </Stack>
                </form>
            </Box>
            <InfoBanner
                open={error !== undefined}
                type="error"
                message={error}
                onClose={handleBannerClose}
            />
        </Stack>
    );
}

export default ForgotPasswordPage;
