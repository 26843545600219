import {useState} from "react";
import {useAppDispatch} from "../../Redux/Hooks";
import {register, resetStatus} from "../../Slices/User/UserSlice";
import {UserRestaurantToCreate} from "../../Models/UserType";
import {Currency} from "../../Models/Currency";
import {LanguageCode} from "../../Models/LanguageCode";

type UserForm = {
    name: string;
    description: string;
    email: string;
    password: string;
    passwordRepeat: string;
    image: string | null;
    currency: Currency;
    language: LanguageCode;
}

export default () => {
    const [formData, setFormData] = useState<UserForm>({
        name: "",
        description: "",
        email: "",
        password: "",
        passwordRepeat: "",
        image: null,
        currency: "TRY",
        language: "tr",
    });
    const dispatch = useAppDispatch();

    const handleFormDataChange = (e: any) => {
        const {id, value} = e.target;
        setFormData({...formData, [id]: value});
    }

    const handleImageChange = (src: string | null) => {
        setFormData({...formData, image: src});
    }

    const handleRegister = () => {
        if (formData.password !== formData.passwordRepeat) {
            alert("Passwords do not match!");
            return;
        }
        const user: UserRestaurantToCreate = {
            email: formData.email,
            password: formData.password,
            restaurant: {
                name: formData.name,
                description: formData.description,
                image: formData.image,
                currency: formData.currency,
                language: formData.language
            },
            locale: window.navigator.language
        }
        dispatch(register(user));
    }

    const handleResetStatus = () => {
        dispatch(resetStatus());
    }

    return [formData, handleFormDataChange, handleImageChange, handleRegister, handleResetStatus] as const;
}
