import {FC, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary, Divider,
    IconButton,
    List, ListItem, ListItemContent,
    Stack,
    Typography
} from "@mui/joy";
import {ChevronLeft} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import {setIsAdjusted, updatePrices} from "../../Slices/AdjustPricesSlice";
import AdjustProductPriceTable from "./AdjustProductPriceTable";
import Button from "@mui/joy/Button";
import {useTranslation} from "react-i18next";

interface Props {
    onClose?: () => void;
}

const PricesPage: FC<Props> = ({onClose}) => {
    const state = useAppSelector(state => state.adjustPrice);
    const categories = state.categories;
    const dispatch = useAppDispatch();
    const [expandedIndex, setExpandedIndex] = useState<number>(0);
    const {t} = useTranslation();

    const handleBackAction = () => {
        dispatch(setIsAdjusted(false));
    }

    const handleExpandAction = (expanded: boolean, index: number) => {
        setExpandedIndex(expanded ? index : -1);
    }

    const handleSaveAction = () => {
        dispatch(updatePrices());
    }

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1} pr={5} pt={2}>
                <IconButton onClick={handleBackAction}><ChevronLeft sx={{fontSize: 35}}/></IconButton>
                <Typography level="h3" textAlign="center">{t("productsPage.adjustPrices.reviewPrices")}</Typography>
            </Stack>
            <AccordionGroup>
                {categories.map(((category, index) => (
                    <Accordion
                        key={category.id}
                        expanded={expandedIndex === index}
                        onChange={(_, expanded) => handleExpandAction(expanded, index)}
                    >
                        <AccordionSummary sx={{typography: 'title-sm'}}>{category.name}</AccordionSummary>
                        <AccordionDetails>
                            <AdjustProductPriceTable products={category.products}/>
                        </AccordionDetails>
                    </Accordion>
                )))}
            </AccordionGroup>
            <Divider></Divider>
            <Stack spacing={1} direction="row" justifyContent="end">
                <Button variant="outlined" onClick={onClose}>{t("cancel")}</Button>
                <Button variant="solid" onClick={handleSaveAction}>{t("save")}</Button>
            </Stack>
        </Stack>
    )
}

export default PricesPage;
