import React, {useEffect, useState} from 'react';
import {Stack, Box, IconButton, Typography} from '@mui/joy';
import MyDrawer from './Drawer/MyDrawer';
import {Menu} from "@mui/icons-material";
import ProductsPage from "./Products/ProductsPage";
import CategoriesPage from "./Categories/CategoriesPage";
import {
    categoriesNavigationItem, getNavigationItemTitle,
    homeNavigationItem,
    NavigationItem,
    optionsNavigationItem,
    productsNavigationItem, profileNavigationItem
} from "./Drawer/NavigationItem";
import {useAppDispatch, useAppSelector} from "../Redux/Hooks";
import {getRestaurant, resetStatus} from "../Slices/Restaurant/RestaurantSlice";
import HomePage from "./HomePage";
import ProfilePage from "./Profile/ProfilePage";
import {checkIsMobile} from "../Helpers/CommonHelpers";
import InfoBanner from "../Components/InfoBanner";
import SettingsPage from "./SettingsPage";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const MainPage = () => {
    const location = useLocation();
    const getCurrentNavigationItem = (): NavigationItem => {
        switch (location.hash) {
            case "#categories":
                return categoriesNavigationItem;
            case "#products":
                return productsNavigationItem;
            case "#profile":
                return profileNavigationItem;
            case "#options":
                return optionsNavigationItem;
            default:
                return homeNavigationItem;
        }
    }

    const restaurantState = useAppSelector(state => state.restaurant);
    const status = restaurantState.status;
    const error = restaurantState.error;
    const [selectedNavigationItem, setSelectedNavigationItem] = useState<NavigationItem>(getCurrentNavigationItem());
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const isMobile = checkIsMobile();
    const dispatch = useAppDispatch();
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const {t} = useTranslation();

    useEffect(() => {
        setSelectedNavigationItem(getCurrentNavigationItem());
    }, [location.hash]);

    useEffect(() => {
        let hash = "#" + selectedNavigationItem.key
        if (selectedNavigationItem.key == "home") {
            hash = "";
        }
        window.location.hash = hash;
    }, [selectedNavigationItem]);

    useEffect(() => {
        dispatch(getRestaurant());
    }, []);

    useEffect(() => {
        if (status === "succeeded" || status === "failed") {
            setShowBanner(true);
        }
    }, [status]);

    const getBannerType = () => {
        if (status === "succeeded") {
            return "success";
        } else if (status === "failed") {
            return "error";
        }
    }

    const handleBannerClose = () => {
        setShowBanner(false)
        dispatch(resetStatus());
    }

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            width="100vw"
            height="100vh"
            spacing={2}
        >
            {isMobile && (
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                    <IconButton sx={{width: 20, ml: 1, mt: 1}} variant="outlined" color="neutral" onClick={toggleDrawer}>
                        <Menu />
                    </IconButton>
                    <Typography sx={{mt: 1}}>{getNavigationItemTitle(selectedNavigationItem.key, t)}</Typography>
                    <div style={{width: 20, marginTop: 1, marginRight: 1}}></div>
                </Stack>
            )}
            <Stack direction="row" justifyContent="center" spacing={2} width="100%" height="100%">
                <MyDrawer sx={{width: "256px", height: "100%"}} item={selectedNavigationItem} isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} onNavigationKeyChange={setSelectedNavigationItem}/>
                <Box width="100%" height="100%">
                    { selectedNavigationItem.key == "home" && <HomePage/> }
                    { selectedNavigationItem.key == "categories" && <CategoriesPage/> }
                    { selectedNavigationItem.key == "products" && <ProductsPage/> }
                    { selectedNavigationItem.key == "profile" && <ProfilePage/> }
                    { selectedNavigationItem.key == "options" && <SettingsPage/> }
                </Box>
            </Stack>
            <InfoBanner
                open={showBanner}
                type={getBannerType()}
                message={error}
                onClose={handleBannerClose}
            />
        </Stack>
    );
};

export default MainPage;
