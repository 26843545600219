import {FC, SyntheticEvent, useEffect, useState} from "react";
import {CategoryType} from "../../Models/Category/CategoryType";
import {Dropdown, Menu, MenuButton, MenuItem, Option, Select, Typography} from "@mui/joy";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {useAppSelector} from "../../Redux/Hooks";
import {SxProps} from "@mui/joy/styles/types";
import {useTranslation} from "react-i18next";

interface CategoryPickerProps {
    defaultCategory: CategoryType | null;
    onChange?: (category: CategoryType | null) => void;
    sx?: SxProps;
}

const CategoryPicker: FC<CategoryPickerProps> = ({defaultCategory, onChange, sx}) => {
    const categoryState = useAppSelector((state) => state.category);
    const categories = categoryState.data;
    const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(defaultCategory ?? null);
    const {t} = useTranslation();

    useEffect(() => {
        setSelectedCategory(defaultCategory ?? null);
    }, [defaultCategory]);

    const handleSelect = (category: CategoryType | null = null) => {
        setSelectedCategory(category);
        onChange?.(category);
    }

    const handleChange = (
        event: SyntheticEvent | null,
        newValue: string | null,
    ) => {
        const category = categories.find((category) => category.id === newValue);
        handleSelect(category ?? null);
    };

    return (
        <Select defaultValue={selectedCategory?.id ?? "all"} sx={sx} onChange={handleChange}>
            <Option value="all">{t("categoriesPage.allCategories")}</Option>
            {categories.map((category) => {
                return (
                    <Option value={category.id} key={category.id}>
                        {category.name}
                    </Option>
                )
            })}
        </Select>
    );
}

export default CategoryPicker;
