import {FC, useEffect} from "react";
import AdjustPricesPage from "./AdjustPricePage";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import {reset, resetStatus} from "../../Slices/AdjustPricesSlice";
import PricesPage from "./PricesPage";
import InfoBanner from "../../Components/InfoBanner";
import useMyTheme from "../../Theme/useMyTheme";

interface Props {
    onClose?: () => void;
}

const AdjustPricesContainer: FC<Props> = ({onClose}) => {
    const state = useAppSelector(state => state.adjustPrice);
    const error = state.error;
    const isAdjusted = state.isAdjusted;
    const dispatch = useAppDispatch();
    const { myTheme } = useMyTheme();

    useEffect(() => {
        if (state.status === "succeeded") {
            onClose && onClose();
        }
    }, [state.status]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        }
    }, []);

    return (
        <div dir={myTheme.direction}>
            {!isAdjusted && <AdjustPricesPage onClose={onClose}/>}
            {isAdjusted && <PricesPage onClose={onClose}/>}
            <InfoBanner
                open={error !== undefined}
                message={error}
                onClose={() => dispatch(resetStatus())}
            />
        </div>
    )
}

export default AdjustPricesContainer;
