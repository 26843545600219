import {FC, useEffect, useState} from "react";
import {Grid, Modal, ModalClose, ModalDialog, Stack, Typography} from "@mui/joy";
import Input from "@mui/joy/Input";
import {Search} from "@mui/icons-material";
import Button from "@mui/joy/Button";
import ProdudctsTable from "./Table/ProductsTable";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import {filterByCategory, filterByStockType, resetStatus, search} from "../../Slices/Product/ProductSlice";
import {CategoryType} from "../../Models/Category/CategoryType";
import CategoryPicker from "../Categories/CategoryPicker";
import CreateEditProductPage from "./Edit/CreateEditProductPage";
import {ProductType} from "../../Models/Product/ProductType";
import StockPicker from "../../Components/StockPicker";
import {reset} from "../../Slices/Product/EditProductSlice";
import InfoBanner from "../../Components/InfoBanner";
import ProductsActionPicker, {ProductAction} from "../../Components/ProductsActionPicker";
import AdjustPricesContainer from "../AdjustPrice/AdjustPriceContainer";
import EqualGridContainer from "../EqualGridContainer";
import {useTranslation} from "react-i18next";

const ProductsPage: FC = () => {
    const productState = useAppSelector(state => state.product);
    const status = productState.status;
    const error = productState.error;
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(null);
    const [isInStockFilterSelected, setIsInStockFilterSelected] = useState<boolean | null>(null);
    const [openCreateProductDialog, setOpenCreateProductDialog] = useState<boolean>(false);
    const [productToEdit, setProductToEdit] = useState<ProductType | null>(null);
    const categoryProducts = useAppSelector(state => state.categoryProduct.data);
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [showAdjustPricesPage, setShowAdjustPricesPage] = useState<boolean>(false);
    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetStatus());
        }
    }, []);

    useEffect(() => {
        if (status === "succeeded" || status === "failed") {
            setShowBanner(true);
        }
    }, [status]);

    const handleBannerClose = () => {
        setShowBanner(false);
        dispatch(resetStatus());
    }

    const getBannerType = () => {
        if (status === "succeeded") {
            return "success";
        } else if (status === "failed") {
            return "error";
        }
    }

    const searchProduct = (event: any) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        dispatch(search(searchTerm));
    }

    const handleCategoryChange = (category: CategoryType | null) => {
        setSelectedCategory(category);
        dispatch(filterByCategory({categoryId: category?.id ?? null, categoryProducts}));
    }

    const handleStockTypeChange = (isInStockFilterSelected: boolean | null) => {
        setIsInStockFilterSelected(isInStockFilterSelected);
        dispatch(filterByStockType(isInStockFilterSelected));
    }

    const openProductEditPage = (product: ProductType | null) => {
        setProductToEdit(product);
        setOpenCreateProductDialog(true);
    }

    const closeProductEditPage = () => {
        setProductToEdit(null);
        setOpenCreateProductDialog(false);
        dispatch(reset());
    }

    const handleActionPicked = (action: ProductAction) => {
        switch (action) {
            case "adjustPrices":
                setShowAdjustPricesPage(true);
                break;
            default:
                break;
        }
    }

    return (
        <Stack width="100%" height="100%">
            <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{width: "100%", pt: 1, pl: 2}}
            >
                <EqualGridContainer
                    xs
                    spacing={2}
                    childrenGrid={{xs: 12, sm: 12, md: true}}
                >
                    <CategoryPicker sx={{width: "100%"}} defaultCategory={selectedCategory} onChange={handleCategoryChange}/>
                    <StockPicker sx={{width: "100%"}} isInStockSelected={isInStockFilterSelected} onChange={handleStockTypeChange}/>
                </EqualGridContainer>
                <Grid
                    xs
                    display="flex"
                    justifyContent="center"
                >
                    <Input fullWidth={true} value={searchTerm} placeholder={t("search")} startDecorator={<Search/>}
                       onChange={searchProduct}/>
                </Grid>
                <EqualGridContainer
                    xs
                    spacing={2}
                    childrenGrid={{xs: 12, sm: 12, md: true, justifyContent: "flex-end", alignItems: "center"}}
                    >
                    <ProductsActionPicker sx={{width: "100%"}} onActionPicked={handleActionPicked}/>
                    <Button fullWidth onClick={() => openProductEditPage(null)}>
                        {t("productsPage.addProduct")}
                    </Button>
                </EqualGridContainer>
            </Grid>
            <ProdudctsTable
                onClick={openProductEditPage}
            />
            <Modal open={openCreateProductDialog} onClose={() => setOpenCreateProductDialog(false)}>
                <ModalDialog>
                    <CreateEditProductPage
                        productToEdit={productToEdit}
                        onClose={() => closeProductEditPage()}
                    />
                </ModalDialog>
            </Modal>
            <Modal open={showAdjustPricesPage} onClose={() => setShowAdjustPricesPage(false)}>
                <ModalDialog>
                    <ModalClose/>
                    <AdjustPricesContainer onClose={() => setShowAdjustPricesPage(false)}/>
                </ModalDialog>
            </Modal>
            <InfoBanner
                open={showBanner}
                type={getBannerType()}
                message={error}
                onClose={handleBannerClose}
            />
        </Stack>
    );
}

export default ProductsPage;
