import {CategoryType} from "../../Models/Category/CategoryType";
import {createEmptyProduct, ProductType} from "../../Models/Product/ProductType";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../Redux/Store";
import {ProductApi} from "../../Api/Product";
import {ApiStatusType} from "../../Models/ApiStatusType";

type EditProductState = {
    product: ProductType;
    categories: CategoryType[];
    status: ApiStatusType;
    savingStatus: ApiStatusType;
    error: string | undefined;
}

const initialState: EditProductState = {
    product: createEmptyProduct(),
    categories: [],
    status: 'idle',
    savingStatus: 'idle',
    error: undefined,
}

export const getCategories = createAsyncThunk(
    'editProduct/getCategories',
    async (_, {getState}) => {
        const rootState = (getState() as RootState);
        return await ProductApi.getCategories(rootState.editProduct.product.id, rootState);
    }
);

export const saveProduct = createAsyncThunk(
    'editProduct/save',
    async (_, {getState}) => {
        const state = (getState() as RootState).editProduct;
        const categoryIds = state.categories.map(category => category.id);
        return await ProductApi.save(state.product, categoryIds);
    }
);

const editProductSlice = createSlice({
    name: 'editProduct',
    initialState: initialState,
    reducers: {
        setData: (state, action: {payload: ProductType | null, type: string }) => {
            state.status = 'idle';
            state.savingStatus = 'idle';
            state.error = undefined;
            if (action.payload) {
                state.product = action.payload;
            } else {
                state.product = createEmptyProduct();
                state.categories = [];
            }
        },
        updateData: (state, action: {payload: ProductType, type: string}) => {
            state.product = {
                ...state.product,
                ...action.payload,
            }
        },
        setCategories: (state, action: {payload: CategoryType[], type: string}) => {
            state.categories = action.payload;
        },
        reset: (state) => {
            state.product = createEmptyProduct();
            state.categories = [];
            state.status = 'idle';
            state.savingStatus = 'idle';
            state.error = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(saveProduct.pending, state => {
                state.savingStatus = 'loading';
            })
            .addCase(saveProduct.fulfilled, (state, action) => {
                state.savingStatus = 'succeeded';
                state.product = action.payload.product;
            })
            .addCase(saveProduct.rejected, (state, action) => {
                state.savingStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(getCategories.pending, state => {
                state.status = 'loading';
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const {setData, updateData, setCategories, reset} = editProductSlice.actions;
export default editProductSlice.reducer;
