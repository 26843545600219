import {FC, useState} from "react";
import {Modal, ModalDialog, Stack, Typography} from "@mui/joy";
import Button from "@mui/joy/Button";
import {ProductType} from "../../Models/Product/ProductType";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import DataPicker from "../../Components/DataPicker";
import {setProducts} from "../../Slices/Category/EditCategorySlice";
import RearrangeProductsView from "./RearrangeProductsView";
import {useTranslation} from "react-i18next";

const ProductsInCategoryView: FC = () => {
    const products = useAppSelector(state => state.product).data;
    const editCategoryState = useAppSelector(state => state.editCategory);
    const [openPickProductDialog, setOpenPickProductDialog] = useState<boolean>(false);
    const [openRearrangeProductsDialog, setOpenRearrangeProductsDialog] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const selectedProducts = editCategoryState.products;
    const count = selectedProducts.length;
    const names = selectedProducts.map(p => p.name).join("•");
    const {t} = useTranslation();

    const handleOnDoneSelectedProducts = (data: ProductType[]) => {
        dispatch(setProducts(data));
        setOpenPickProductDialog(false);
    }

    return (
        <Stack spacing={4}>
            <Typography level="h4">{t("productsPage.title")}</Typography>
            <Stack direction="row" justifyContent="space-between">
                <Stack>
                    <Typography level="body-lg">{t("productsPage.productWithCount", {count: count})}</Typography>
                    <Typography level="body-sm">{names}</Typography>
                </Stack>
                <Button variant="plain" onClick={() => setOpenRearrangeProductsDialog(true)}>{t("edit")}</Button>
            </Stack>
            <Button variant="soft" onClick={() => setOpenPickProductDialog(true)}>{t("productsPage.addOrRemoveProduct")}</Button>
            <Modal open={openPickProductDialog} onClose={() => setOpenPickProductDialog(false)}>
                <ModalDialog>
                    <DataPicker
                        pickedData={selectedProducts}
                        data={products} title={t("productsPage.title")}
                        onDone={handleOnDoneSelectedProducts}
                        onClose={() => setOpenPickProductDialog(false)}
                    />
                </ModalDialog>
            </Modal>
            <Modal open={openRearrangeProductsDialog} onClose={() => setOpenRearrangeProductsDialog(false)}>
                <ModalDialog>
                    <RearrangeProductsView onClose={() => setOpenRearrangeProductsDialog(false)}/>
                </ModalDialog>
            </Modal>
        </Stack>
    );
}

export default ProductsInCategoryView;
