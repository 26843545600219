import {FC} from "react";
import {Dropdown, Menu, MenuButton, MenuItem, Typography} from "@mui/joy";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {SxProps} from "@mui/joy/styles/types";
import {useTranslation} from "react-i18next";

export type ProductAction = "adjustPrices"

interface Props {
    onActionPicked: (action: ProductAction) => void;
    sx?: SxProps;
}

const ProductsActionPicker: FC<Props> = ({onActionPicked, sx}) => {
    const {t} = useTranslation();
    const actions: ProductAction[] = ["adjustPrices"];

    const getActionName = (action: ProductAction) => {
        switch (action) {
            case "adjustPrices":
                return t("productsPage.adjustPrices.title");
        }
    }

    const handleSelect = (action: ProductAction) => {
        onActionPicked(action);
    }

    return (
        <Dropdown>
            <MenuButton sx={{...sx, display: "flex", justifyContent: "space-between"}} variant="soft" endDecorator={<ArrowDropDown />}>
                {t("multiAction")}
            </MenuButton>
            <Menu sx={{maxHeight: "70vh", overflow: "auto"}}>
                {actions.map((action, index) => {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                handleSelect(action);
                            }}
                        >
                            {getActionName(action)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </Dropdown>
    );
}

export default ProductsActionPicker;
