import {FC, useEffect} from "react";
import {Card, CardActions, CardContent, Stack} from "@mui/joy";
import Button from "@mui/joy/Button";
import {useAppDispatch, useAppSelector} from "../Redux/Hooks";
import {logout, resetStatus} from "../Slices/User/UserSlice";
import InfoBanner from "../Components/InfoBanner";
import {useTranslation} from "react-i18next";
import {Configs} from "../Configs";
import Icon from "../Resources/Icon.svg";

const HomePage: FC = () => {
    const userState = useAppSelector(state => state.user);
    const error = userState.error;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (userState.status === "succeeded" && userState.user === null) {
            window.location.href = "/";
        }
    }, [userState.status]);

    useEffect(() => {
        return () => {
            dispatch(resetStatus());
        }
    }, []);

    const handleBannerClose = () => {
        dispatch(resetStatus());
    }

    const handleLogout = (e: any) => {
        e.preventDefault();
        dispatch(logout());
    }

    return (
        <Stack sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        }}>
            <Card>
                <CardContent sx={{alignItems: "center"}}>
                    <img src={Icon} width={120} alt=""/>
                    <p style={{textAlign: "center"}}>{t('greeting', {appName: Configs.appName})}</p>
                </CardContent>
                <CardActions>
                    <Stack justifyContent="center" spacing={2} direction="row" sx={{width: "100%"}}>
                        <Button type="button" onClick={handleLogout}>
                            {t('logout')}
                        </Button>
                    </Stack>
                </CardActions>
            </Card>
            <InfoBanner
                open={error !== undefined}
                type="error"
                message={error}
                onClose={handleBannerClose}
            />
        </Stack>
    );
}

export default HomePage;
