import Parse from "parse";

const tryFunction = async (promise: Promise<any>, isUserApi: boolean = false): Promise<any> => {
    try {
        return await promise;
    } catch (error) {
        let code= 400;
        if (error instanceof Parse.Error) {
            if (error.code === 203 ||
                (error.code === 101 && isUserApi)) {
                throw error;
            }
            code = error.code;
        }
        console.log(error);
        throw new Parse.Error(code, "Bir hata oluştu.");
    }
}

export const ThrowHandler = {tryFunction};
