import {FC} from "react";
import {Card, CardActions, CardContent, Stack} from "@mui/joy";
import { Link as RouterLink } from 'react-router-dom';
import LinkButton from "../Components/LinkButton";
import {Configs} from "../Configs";
import {useTranslation} from "react-i18next";
import icon from '../Resources/Icon.svg';

const WelcomePage: FC = () => {
    const {t} = useTranslation();

    return (
        <Stack sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
        }}>
            <Card>
                <h1 style={{textAlign: "center", margin: 0}}>{Configs.appName}</h1>
                <CardContent sx={{alignItems: "center"}}>
                    <img src={icon} alt="" style={{width: 120}}/>
                    <p style={{textAlign: "center"}}>{t("appDescription", {appName: Configs.appName})}</p>
                </CardContent>
                <CardActions>
                    <Stack justifyContent="center" spacing={2} direction="row" sx={{width: "100%"}}>
                        <LinkButton sx={{minWidth: 80}} variant="solid" component={RouterLink} to="/register">
                            {t("signUp.signUpButtonTitle")}
                        </LinkButton>
                        <div></div>
                        <LinkButton sx={{minWidth: 80}} variant="solid" component={RouterLink} to="/login">
                            {t("login.loginButtonTitle")}
                        </LinkButton>
                    </Stack>
                </CardActions>
            </Card>
        </Stack>
    );
}

export default WelcomePage;
