import {useAppDispatch, useAppSelector} from "../../../Redux/Hooks";
import {RootState} from "../../../Redux/Store";
import {changePage, deleteProducts, handleSelectionChange} from "../../../Slices/Product/ProductSlice";
import {ProductType} from "../../../Models/Product/ProductType";

export default () => {
    const productState = useAppSelector((state: RootState) => state.product);
    const selectedProducts = productState.selectedData;
    const products = productState.dataOnCurrentPage;

    const dispatch = useAppDispatch();

    const handleAllProductSelection = (event: any) => {
        dispatch(handleSelectionChange({products, isSelected: event.target.checked}));
    }

    const handleProductSelection = (event: any, product: ProductType) => {
        dispatch(handleSelectionChange({products: [product], isSelected: event.target.checked}));
    }

    const selectPage = (page: number) => {
        dispatch(handleSelectionChange({products: selectedProducts, isSelected: false}));
        dispatch(changePage(page));
    }

    const deleteSelected = () => {
        dispatch(deleteProducts());
    }

    const isProductSelected = (product: ProductType) => {
        return selectedProducts.find(p => p.id === product.id) !== undefined;
    }

    return [handleAllProductSelection, handleProductSelection, selectPage, deleteSelected, isProductSelected] as const;
}
