export type Currency = "TRY" | "USD" | "EUR"
export const currencies: Currency[] = ["TRY", "USD", "EUR"]
export const formatPrice = (price: number, currency: Currency): string => {
    switch (currency) {
        case "TRY":
            return Intl.NumberFormat("tr-TR", {style: "currency", currency: "TRY"}).format(price)
        case "USD":
            return Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(price)
        case "EUR":
            return Intl.NumberFormat("en-US", {style: "currency", currency: "EUR"}).format(price)
    }
}
