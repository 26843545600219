import {FC, SyntheticEvent} from "react";
import {Option, Select} from "@mui/joy";
import {SxProps} from "@mui/joy/styles/types";
import {useTranslation} from "react-i18next";

type StockType = "all" | "inStock" | "notInStock";

interface Props {
    isInStockSelected: boolean | null;
    onChange?: (category: boolean | null) => void;
    sx?: SxProps;
}

const StockTypePicker: FC<Props> = ({isInStockSelected, onChange, sx}) => {
    const {t} = useTranslation();

    const handleSelect = (isInStockSelected: boolean | null = null) => {
        onChange?.(isInStockSelected);
    }

    const type = (): StockType => {
        switch (isInStockSelected) {
            case true:
                return "inStock";
            case false:
                return "notInStock";
            default:
                return "all";
        }
    }

    const handleChange = (
        event: SyntheticEvent | null,
        newValue: StockType | null,
    ) => {
        switch (newValue) {
            case "inStock":
                handleSelect(true);
                break;
            case "notInStock":
                handleSelect(false);
                break;
            default:
                handleSelect(null);
                break;
        }
    }

    return (
        <Select defaultValue={type()} sx={sx} onChange={handleChange}>
            <Option value="all">{t("productsPage.allProducts")}</Option>
            <Option value="inStock">{t("productsPage.inStocks")}</Option>
            <Option value="notInStock">{t("productsPage.outOfStocks")}</Option>
        </Select>
    );
}

export default StockTypePicker;
