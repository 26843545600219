import Parse from "parse";
import {UserRestaurantToCreate, UserType} from "../Models/UserType";
import {ThrowHandler} from "../ThrowHandler";
import {convertUser} from "./Converters";

const login = async (email: string, password: string) => {
    const parseUser = await ThrowHandler.tryFunction(Parse.User.logIn(email, password), true);
    const user = convertUser(parseUser);
    return user;
}

const register = async (user: UserRestaurantToCreate) => {
    return await ThrowHandler.tryFunction(Parse.Cloud.run("register", user), true);
}

const requestPasswordReset = async (email: string) => {
    return await ThrowHandler.tryFunction(Parse.User.requestPasswordReset(email), true);
}

const logout = async () => {
    await Parse.User.logOut();
}

const getUser = async () => {
    const parseUser = await ThrowHandler.tryFunction(Parse.User.currentAsync(), true);
    const user: UserType = convertUser(parseUser);
    return user;
}

export const userApi = {login, register, requestPasswordReset, logout, getUser};
