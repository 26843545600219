import {CSSProperties, FC, ReactNode} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

interface Props {
    id: string;
    style?: CSSProperties;
    onClick?: () => void;
    children?: ReactNode;
}

const SortableItem: FC<Props> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const thisStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const style = props.style ? {...props.style, ...thisStyle} : thisStyle;

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} onClick={props.onClick}>
            {props.children}
        </div>
    );
}

export default SortableItem;
