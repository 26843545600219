import {FC, useEffect, useRef} from "react";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import ImagePicker from "../../Components/ImagePicker";
import {Box, Stack} from "@mui/joy";
import {useAppSelector} from "../../Redux/Hooks";
import {Link as RouterLink} from "react-router-dom";
import InfoModal from "../../Components/InfoModal";
import LinkButton from "../../Components/LinkButton";
import useRegisterPageHooks from "./useRegisterPageHooks";
import {useNavigate} from "react-router";
import InfoBanner from "../../Components/InfoBanner";
import {useTranslation} from "react-i18next";

const RegisterPage: FC = () => {
    const userState = useAppSelector(state => state.user);
    const error = userState.error;
    const formRef = useRef<HTMLFormElement>(null);
    const [formData, handleFormDataChange, handleImageChange, handleRegister, handleResetStatus] = useRegisterPageHooks();
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        return () => {
            handleResetStatus();
        }
    }, []);

    const handleBannerClose = () => {
        handleResetStatus();
    }

    const handleSubmit = (e: any) => {
        e.preventDefault(); // This will always prevent the page refresh
        if (formRef.current && !formRef.current.reportValidity()) {
            return;
        }
        handleRegister();
    }

    const handleSuccess = () => {
        handleResetStatus();
        navigate("/");
    }

    return (
        <Stack sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
        }}>
            <Box sx={{width: "50%", heigth: "50%"}}>
                <h1 style={{textAlign: "center"}}>{t("signUp.title")}</h1>
                <form ref={formRef}>
                    <Stack spacing={2}>
                        <Input
                            id="name"
                            value={formData.name}
                            type={"text"}
                            placeholder={t("signUp.restaurantName")}
                            required
                            onChange={handleFormDataChange}
                        />
                        <Input
                            id="description"
                            value={formData.description}
                            type={"text"}
                            placeholder={t("signUp.restaurantDescription")}
                            onChange={handleFormDataChange}
                        />
                        <Input
                            id="email"
                            value={formData.email}
                            type={"email"}
                            placeholder={t("email")}
                            required
                            onChange={handleFormDataChange}
                        />
                        <Input
                            id="password"
                            value={formData.password}
                            type={"password"}
                            placeholder={t("password")}
                            required
                            onChange={handleFormDataChange}
                        />
                        <Input
                            id="passwordRepeat"
                            value={formData.passwordRepeat}
                            type={"password"}
                            placeholder={t("signUp.passwordRepeat")}
                            required
                            onChange={handleFormDataChange}
                        />
                        <ImagePicker src={formData.image} onFileChange={handleImageChange}/>
                        <Button type="button" onClick={handleSubmit}>
                            {t("signUp.signUpButtonTitle")}
                        </Button>
                        <Stack spacing={0.5} alignItems="center">
                            <p style={{margin: 0}}>{t("signUp.alreadyRegistered")}</p>
                            <LinkButton component={RouterLink} to="/login">
                                {t("login.loginButtonTitle")}
                            </LinkButton>
                        </Stack>
                    </Stack>
                </form>
             </Box>
            <InfoModal
                open={userState.status === "succeeded"}
                title={"Başvurunuz Alındı"}
                description={"Başvurunuz en kısa sürede incelenecektir."}
                buttonTitle={"Tamam"}
                onButtonClick={handleSuccess}
                onClose={handleSuccess}
            />
            <InfoBanner
                open={error !== undefined}
                type="error"
                message={error}
                onClose={handleBannerClose}
            />
        </Stack>
    );
}

export default RegisterPage;
