import {FC, useEffect, useRef,} from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import {DialogContent, Divider, IconButton, Sheet, Stack} from "@mui/joy";
import {Close} from "@mui/icons-material";
import ImagePicker from "../../../Components/ImagePicker";
import {CategoryType} from "../../../Models/Category/CategoryType";
import {useAppSelector} from "../../../Redux/Hooks";
import ProductsInCategoryView from "../ProductsInCategoryView";
import useEditCategoryHooks from "./useEditCategoryHooks";
import useMyTheme from "../../../Theme/useMyTheme";
import {useTranslation} from "react-i18next";

type Props = {
    categoryToEdit: CategoryType | null;
    onClose?: () => void;
}

const CreateEditProductPage: FC<Props> = ({onClose, categoryToEdit}) => {
    const [handleData, save, handleCategoryChange] = useEditCategoryHooks();
    const formRef = useRef<HTMLFormElement>(null);
    const state = useAppSelector(state => state.editCategory);
    const category = state.category;
    const { myTheme } = useMyTheme();
    const {t} = useTranslation();

    useEffect(() => {
        handleData(categoryToEdit);
        return () => {
            handleData(null);
        }
    }, []);


    useEffect(() => {
        if (state.savingStatus === "succeeded") {
            onClose && onClose();
        }
    }, [state.savingStatus]);

    const handleSubmit = (e: any) => {
        e.preventDefault(); // This will always prevent the page refresh
        if (formRef.current && !formRef.current.reportValidity()) {
            return;
        }
        save();
    }

    const handleValueChange = (e: any) => {
        const {id, value} = e.target;
        const updatedCategory = {...category, [id]: value};
        handleCategoryChange(updatedCategory);
    }

    const handleImageChange = (src: string | null) => {
        const updatedCategory = {...category, image: {src: src, isNew: true}};
        handleCategoryChange(updatedCategory);
    }

    return (
        <Sheet dir={myTheme.direction} sx={{minWidth: "40vw", overflow: "scroll"}}>
                <form ref={formRef}>
                    <Stack spacing={4}>
                        <Stack direction="row" justifyContent="space-between">
                            <IconButton onClick={onClose}>
                                <Close/>
                            </IconButton>
                            <Button type="button" onClick={handleSubmit}>Kaydet</Button>
                        </Stack>
                        <Input id="name" value={category.name} placeholder={t("categoriesPage.categoryName")} required
                               onChange={handleValueChange}/>
                        <Input id="description" value={category.description ?? undefined} placeholder={t("description")}
                               onChange={handleValueChange}/>
                        <ImagePicker style={{width: '100%'}} src={category.image.src} onFileChange={handleImageChange}/>
                        <Divider></Divider>
                        <ProductsInCategoryView/>
                    </Stack>
                </form>
        </Sheet>
    );
}

export default CreateEditProductPage;
