import {UserState} from "../../Models/UserState";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {UserRestaurantToCreate} from "../../Models/UserType";
import {userApi} from "../../Api/User";
import Parse from "parse";
import {convertUser} from "../../Api/Converters";
import {getRestaurant, saveRestaurant} from "../Restaurant/RestaurantSlice";

const initialState: UserState = {
    user: null,
    status: 'idle',
    error: undefined,
}

export const login = createAsyncThunk(
    'user/login',
    async (arg: {email: string, password: string}) => {
        return await userApi.login(arg.email, arg.password);
    }
)

export const logout = createAsyncThunk(
    'user/logout',
    async () => {
        await userApi.logout();
    }
)

export const register = createAsyncThunk(
    'user/register',
    async (user: UserRestaurantToCreate) => {
        return await userApi.register(user);
    }
)

export const requestPasswordReset = createAsyncThunk(
    'user/passwordReset',
    async (email: string) => {
        return await userApi.requestPasswordReset(email);
    }
)

export const getUser = createAsyncThunk(
    'user/info',
    async () => {
        return await userApi.getUser();
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state) => {
            const currentParseUser = Parse.User.current();
            if (currentParseUser) {
                state.user = convertUser(currentParseUser);
            } else {
                state.user = null;
            }
        },
        resetStatus: (state) => {
            state.status = 'idle';
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(login.fulfilled, (state, {payload}) => {
                state.status = 'succeeded';
                state.user = payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(register.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(register.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(register.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUser.fulfilled, (state, {payload}) => {
                state.status = 'succeeded';
                state.user = payload;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(requestPasswordReset.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestPasswordReset.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(requestPasswordReset.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(logout.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(logout.fulfilled, (state) => {
                state.status = 'succeeded';
                state.user = null;
            })
            .addCase(logout.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getRestaurant.fulfilled, (state, {payload}) => {
                state.user = payload.user;
            })
            .addCase(saveRestaurant.fulfilled, (state, {payload}) => {
                if (state.user) {
                    const newUser = {...state.user};
                    newUser.locale = payload.language
                    state.user = newUser;
                }
            })
    }
});

export const {setUser,resetStatus} = userSlice.actions;

export default userSlice.reducer;
