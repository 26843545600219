import * as React from 'react';
import {FC, useEffect} from "react";
import {Option, Select} from "@mui/joy";
import {AdjustPriceType} from "../../Models/AdjustPriceState";
import {SxProps} from "@mui/joy/styles/types";

interface Props {
    value?: AdjustPriceType;
    onChange?: (value: AdjustPriceType) => void;
    sx?: SxProps;
}

const AdjustPriceTypePicker: FC<Props> = ({value, onChange, sx}) => {
    const [selectedType, setSelectedType] = React.useState<AdjustPriceType>(value ?? "increase");

    useEffect(() => {
        setSelectedType(value ?? "increase");
    }, [value]);

    const handleSelect = (type: AdjustPriceType | null) => {
        value = type ?? "increase";
        setSelectedType(type ?? "increase");
        onChange && onChange(type ?? "increase");
    }

    return (
        <Select
            sx={sx}
            value={selectedType}
            onChange={(_, value) => handleSelect(value)}>
            <Option value={"increase"}>Arttır</Option>
            <Option value={"decrease"}>Azalt</Option>
        </Select>
    );
}

export default AdjustPriceTypePicker;
