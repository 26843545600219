import {RestaurantType} from "../Models/RestaurantType";
import {ProductType} from "../Models/Product/ProductType";
import {CategoryType} from "../Models/Category/CategoryType";
import Parse from "parse";
import {CategoryProductType} from "../Models/CategoryProductType";
import {UserType} from "../Models/UserType";
import {LanguageCode} from "../Models/LanguageCode";

export type ImageType = {
    src: string | null;
    isNew: boolean;
}

export const convertLocaleToLanguage = (locale: string): LanguageCode => {
    let newLocale = "tr";
    const parts = locale.split("-");
    if (parts.length > 0) {
        newLocale = parts[0];
    }
    switch (newLocale) {
        case "en":
            return "en";
        case "ar":
            return "ar";
        default:
            return "tr";
    }
}

export const convertUser = (user: Parse.User): UserType => {
    return {
        id: user.id,
        email: user.get("email"),
        locale: convertLocaleToLanguage(user.get("locale")),
    }
}

const convertImage = (image: Parse.File | undefined): ImageType => {
    return {
        src: image?.url() ?? null,
        isNew: false,
    }
}

export const convertRestaurant = (restaurant: Parse.Object): RestaurantType => {
    return {
        id: restaurant.id,
        name: restaurant.get("name"),
        description: restaurant.get("description") ?? null,
        image: convertImage(restaurant.get("image")),
        userId: restaurant.get("user").id,
        status: restaurant.get("status"),
        currency: restaurant.get("currency"),
        language: convertLocaleToLanguage(restaurant.get("user").get("locale")),
    }
}

export const convertProduct = (product: Parse.Object): ProductType => {
    return {
        id: product.id,
        name: product.get("name"),
        description: product.get("description") ?? null,
        image: convertImage(product.get("image")),
        price: product.get("price"),
        available: product.get("available"),
    }
}

export const convertProducts = (products: Parse.Object[]): ProductType[] => {
    return products.map(product => convertProduct(product));
}

export const convertCategory = (category: Parse.Object): CategoryType => {
    return {
        id: category.id,
        name: category.get("name"),
        index: category.get("index"),
        description: category.get("description"),
        image: convertImage(category.get("image")),
    }
}

export const convertCategories = (categories: Parse.Object[]): CategoryType[] => {
    return categories.map(category => convertCategory(category));
}

export const convertCategoryProduct = (categoryProduct: Parse.Object): CategoryProductType => {
    return {
        categoryId: categoryProduct.get("category").id,
        productId: categoryProduct.get("product").id,
        index: categoryProduct.get("index"),
    }
}

export const convertCategoryProducts = (categoryProducts: Parse.Object[]): CategoryProductType[] => {
    return categoryProducts.map(categoryProduct => convertCategoryProduct(categoryProduct))
}
