export type PageInfo = {
    currentPage: number,
    dataCountPerPage: number,
    total: number,
}

export const createPageInfo = (): PageInfo => {
    return {
        currentPage: 0,
        dataCountPerPage: 20,
        total: 1,
    }
}
