import {FC, useEffect, useState} from "react";
import {Dropdown, Menu, MenuButton, MenuItem} from "@mui/joy";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {useAppDispatch, useAppSelector} from "../Redux/Hooks";
import {currencies, Currency} from "../Models/Currency";
import {saveRestaurant} from "../Slices/Restaurant/RestaurantSlice";

const CurrencyPicker: FC = () => {
    const restaurant = useAppSelector((state) => state.restaurant).data;
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(restaurant?.currency ?? "TRY");

    const dispatch = useAppDispatch();

    useEffect(() => {
        setSelectedCurrency(restaurant?.currency ?? "TRY");
    }, [restaurant]);

    const handleSelect = (currency: Currency) => {
        setSelectedCurrency(currency);
        if (restaurant) {
            dispatch(saveRestaurant({...restaurant, currency}));
        }
    }

    return (
        <Dropdown>
            <MenuButton variant="soft" endDecorator={<ArrowDropDown />}>{selectedCurrency}</MenuButton>
            <Menu sx={{maxHeight: "70vh", overflow: "auto"}}>
                {currencies.map((currency, index) => {
                    return (
                        <MenuItem
                            key={index}
                            selected={selectedCurrency === currency}
                            onClick={() => {
                                handleSelect(currency);
                            }}
                        >
                            {currency}
                        </MenuItem>
                    );
                })}
            </Menu>
        </Dropdown>
    );
}

export default CurrencyPicker;
