import {configureStore} from "@reduxjs/toolkit";
import productSlice from "../Slices/Product/ProductSlice";
import categorySlice from "../Slices/Category/CategorySlice";
import editCategorySlice from "../Slices/Category/EditCategorySlice";
import editProductSlice from "../Slices/Product/EditProductSlice";
import userSlice from "../Slices/User/UserSlice";
import categoryProductSlice from "../Slices/CategoryProduct/CategoryProductSlice";
import restaurantSlice from "../Slices/Restaurant/RestaurantSlice";
import adjustPricesSlice from "../Slices/AdjustPricesSlice";

const store = configureStore({
    reducer: {
        product: productSlice,
        category: categorySlice,
        editCategory: editCategorySlice,
        editProduct: editProductSlice,
        user: userSlice,
        categoryProduct: categoryProductSlice,
        restaurant: restaurantSlice,
        adjustPrice: adjustPricesSlice
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
