import {BaseModel, DefaultModel, ImageModel} from "../BaseModel";

export type ProductType = DefaultModel & {
    description: string | null;
    image: {
        src: string | null;
        isNew: boolean;
    };
    price: number;
    available: boolean;
}

export const createEmptyProduct = (): ProductType => {
    return {
        id: '',
        name: '',
        description: '',
        image: {
            src: null,
            isNew: false
        },
        price: 0,
        available: true,
    }
}
