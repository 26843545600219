import {RestaurantState} from "../../Models/RestaurantState";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RestaurantInfoType} from "../../Models/RestaurantInfoType";
import {restauarantAPI} from "../../Api/Restaurant";
import {RestaurantType} from "../../Models/RestaurantType";

const initialState: RestaurantState = {
    data: null,
    categoryProducts: [],
    status: 'idle',
    error: undefined,
};

export const getRestaurant = createAsyncThunk(
    'restaurant/info',
    async () => {
        const restaurant = await restauarantAPI.getRestaurantInfo()
        return restaurant;
    },
);

export const saveRestaurant = createAsyncThunk(
    'restaurant/save',
    async (restaurant: RestaurantType) => {
        const savedRestaurant = await restauarantAPI.saveRestaurant(restaurant)
        return savedRestaurant;
    },
);

const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState: initialState,
    reducers: {
        resetStatus: state => {
            state.status = 'idle';
            state.error = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getRestaurant.pending, state => {
                state.status = 'loading';
            })
            .addCase(getRestaurant.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload.restaurant;
            })
            .addCase(getRestaurant.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(saveRestaurant.pending, state => {
                state.status = 'loading';
            })
            .addCase(saveRestaurant.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(saveRestaurant.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export default restaurantSlice.reducer;

export const {resetStatus} = restaurantSlice.actions;
