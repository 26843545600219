import React, {FC, useEffect, useState} from 'react';
import {List, ListItem, Drawer, ListItemButton, ListItemDecorator, Box} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import {getNavigationItemTitle, items, NavigationItem} from "./NavigationItem";
import {SxProps} from "@mui/joy/styles/types";
import {useTranslation} from "react-i18next";

interface ContentProps {
    item: NavigationItem;
    onNavigationKeyChange?: (item: NavigationItem) => void;
    sx?: SxProps;
}

const MyDrawerContent: FC<ContentProps> = ({item, onNavigationKeyChange, sx}) => {
    const [selectedItem, setSelectedItem] = useState<NavigationItem>(item);
    const {t} = useTranslation();

    useEffect(() => {
        setSelectedItem(item);
    }, [item]);

    const handleItemClick = (key: NavigationItem) => {
        setSelectedItem(key);
        if (onNavigationKeyChange) {
            onNavigationKeyChange(key);
        }
    }

    return <Box>
        <List sx={sx}>
            {items.map((item) => (
                <ListItem key={item.key}>
                    <ListItemButton selected={selectedItem.key == item.key}
                                    onClick={() => handleItemClick(item)}>
                        <ListItemDecorator>
                            <item.icon/>
                        </ListItemDecorator>
                        {getNavigationItemTitle(item.key, t)}
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Box>
}

interface MyDrawerProps {
    item: NavigationItem;
    isOpen: boolean;
    toggleDrawer: () => void;
    onNavigationKeyChange?: (item: NavigationItem) => void;
    sx?: SxProps;
}

const MyDrawer: FC<MyDrawerProps> = ({item, isOpen, toggleDrawer, onNavigationKeyChange, sx}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(isOpen);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        setIsDrawerOpen(isOpen);
    }, [isOpen]);

    const handleOnNavigationItemChange = (item: NavigationItem) => {
        toggleDrawer();
        setIsDrawerOpen(false);
        if (onNavigationKeyChange) {
            onNavigationKeyChange(item);
        }
    }

    if (isMobile) {
        return (
            <Drawer size={"sm"} open={isDrawerOpen} onClose={toggleDrawer} sx={sx}>
                <MyDrawerContent item={item} onNavigationKeyChange={handleOnNavigationItemChange}/>
            </Drawer>
        );
    } else {
        return <MyDrawerContent item={item} onNavigationKeyChange={onNavigationKeyChange} sx={sx}/>
    }
};

export default MyDrawer;
