import {FC, useState} from "react";
import {Stack, Table} from "@mui/joy";
import {RootState} from "../../../Redux/Store";
import {useAppSelector} from "../../../Redux/Hooks";
import {CategoryType} from "../../../Models/Category/CategoryType";
import {SxProps} from "@mui/joy/styles/types";
import type {DragEndEvent} from "@dnd-kit/core/dist/types";
import useCategoriesTableHooks from "./useCategoriesTableHooks";
import SortableTableContext from "../../../Components/SortableTableContext";
import CategoriesTableHeader from "./CategoriesTableHeader";
import CategoryRow from "./CategoryRow";
import Pagination from "../../../Components/Pagination";
import Button from "@mui/joy/Button";
import InfoModal from "../../../Components/InfoModal";

interface Props {
    isEditing?: boolean;
    onClick: (category: CategoryType) => void;
    sx?: SxProps;
}

const CategoriesTable: FC<Props> = ({isEditing, onClick, sx}) => {
    const [handleAllCategorySelection, handleCategorySelection, deleteSelected, isCategorySelected, selectPage, handleIndexesOnCurrentPage] = useCategoriesTableHooks();
    const categoryState = useAppSelector((state: RootState) => state.category);
    const categories = categoryState.dataOnCurrentPage;
    const selectedCategories = categoryState.selectedData;
    const pageInfo = categoryState.pageInfo;
    const selectedCount = selectedCategories.length;
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const handleCategoryClick = (category: CategoryType) => {
        onClick(category);
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;
        if (over && active.id !== over.id) {
            const oldIndex = categories.findIndex((item) => item.id === active.id);
            const newIndex = categories.findIndex((item) => item.id === over.id);
            handleIndexesOnCurrentPage(oldIndex, newIndex);
        }
    }

    const handleDeleteConfirmation = () => {
        setShowDeleteModal(false);
        deleteSelected();
    }

    return (
        <Stack justifyContent="space-between" sx={{...sx, height: "100%"}}>
            <Stack sx={{p: 1}}>
                <SortableTableContext
                    disabled={!isEditing}
                    items={categories}
                    handleDragEnd={handleDragEnd}
                >
                    <Table hoverRow>
                        <CategoriesTableHeader
                            isEditing={isEditing}
                            selectionDisabled={selectedCount === 0 && categories.length === 0}
                            selectionChecked={categories.length === selectedCount && selectedCount > 0}
                            onSelectionChange={handleAllCategorySelection}
                        />
                            <tbody>
                            {categories.map(category => {
                                return <CategoryRow
                                    key={category.id}
                                    isEditing={isEditing}
                                    category={category}
                                    isSelected={isCategorySelected(category)}
                                    onClick={() => handleCategoryClick(category)}
                                    onSelectChange={e => handleCategorySelection(e, category)}
                                />
                            })}
                            </tbody>
                    </Table>
                </SortableTableContext>
                <Pagination count={pageInfo.total} currentPage={pageInfo.currentPage} setPage={selectPage}/>
            </Stack>
            {selectedCount > 0 && (
                <Stack direction="row" alignItems="center" justifyContent={"space-between"} sx={{p: 1, backgroundColor: "background.level1"}}>
                    Selected: {selectedCount}
                    <Button color="danger" onClick={() => setShowDeleteModal(true)}>
                        Delete
                    </Button>
                </Stack>
            )}
            <InfoModal
                open={showDeleteModal}
                title="Are you sure to delete?"
                buttonTitle="Delete"
                onButtonClick={handleDeleteConfirmation}
                onClose={() => setShowDeleteModal(false)}
            />
        </Stack>
    );
}

export default CategoriesTable;
