import {FC, useEffect, useState} from "react";
import {IconButton, Snackbar, Stack} from "@mui/joy";
import {CheckCircle, Close, Error, Info, Warning} from "@mui/icons-material";

type InfoType = "info" | "warning" | "error" | "success";

interface Props {
    open: boolean;
    type?: InfoType;
    message?: string;
    onClose?: () => void;
}

const InfoBanner: FC<Props> = ({open, type, message, onClose}) => {
    const [show, setShow] = useState<boolean>(open);
    const infoType = type ? type : "info";

    useEffect(() => {
        setShow(open);
    }, [open]);

    const getColor = (): "warning" | "success" | "primary" | "neutral" | "danger" => {
        switch (infoType) {
            case "info":
                return "primary";
            case "warning":
                return "warning";
            case "error":
                return "danger";
            case "success":
                return "success";
        }
    }

    const getstartDecorator = () => {
        switch (infoType) {
            case "info":
                return <Info/>
            case "warning":
                return <Warning/>
            case "error":
                return <Error/>
            case "success":
                return <CheckCircle/>
        }
    }

    const getTitle = () => {
        switch (infoType) {
            case "error":
                return "Hata!";
        }
    }

    const getMessage = () => {
        if (message) {
            return message;
        }
        switch (infoType) {
            case "error":
                return "Bir hata oluştu."
            case "success":
                return "İşlem başarılı."
        }
    }

    const handleClose = () => {
        setShow(false);
        open = false;
        onClose && onClose();
    }

    return (
        <Snackbar
            open={show}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            autoHideDuration={3000}
            color={getColor()}
            size="sm"
            startDecorator={getstartDecorator()}
            endDecorator={
                <IconButton onClick={handleClose}>
                    <Close/>
                </IconButton>
            }
            onClose={handleClose}
        >
            <Stack>
                {getTitle() && <h2 style={{padding: 0, margin: 0}}>{getTitle()}</h2>}
                <p style={{padding: 0, margin: 0}}>{getMessage()}</p>
            </Stack>
        </Snackbar>
    )
}

export default InfoBanner;
