import {CategoryProductState} from "../../Models/CategoryProductState";
import {createSlice} from "@reduxjs/toolkit";
import {getRestaurant} from "../Restaurant/RestaurantSlice";
import {saveCategory} from "../Category/EditCategorySlice";
import {saveProduct} from "../Product/EditProductSlice";

const initialState: CategoryProductState = {
    data: [],
    status: 'idle',
    error: undefined,
}

export const categoryProductSlice = createSlice({
    name: 'categoryProduct',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getRestaurant.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.categoryProducts;
            })
            .addCase(saveCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const filteredData = state.data.filter(categoryProduct => categoryProduct.categoryId !== action.payload.category.id);
                state.data = [...filteredData, ...action.payload.categoryProducts];
            })
            .addCase(saveProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const filteredData = state.data.filter(categoryProduct => categoryProduct.productId !== action.payload.product.id);
                state.data = [...filteredData, ...action.payload.categoryProducts];
            })
    }
});

export default categoryProductSlice.reducer;
