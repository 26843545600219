import {FC} from "react";
import {List, ListItem, Stack, Typography} from "@mui/joy";
import CurrencyPicker from "../Components/CurrencyPicker";
import ListDivider from "@mui/joy/ListDivider";
import LanguagePicker from "../Components/LanguagePicker";
import {checkIsMobile} from "../Helpers/CommonHelpers";
import {useTranslation} from "react-i18next";

const SettingsPage: FC = () => {
    const isMobile = checkIsMobile();
    const {t} = useTranslation();

    return (
        <Stack width={isMobile ? "90%" : "50%"} p={1}>
            <List>
                <ListItem>
                    <Stack spacing={1}>
                        <Typography level="title-sm">{t("currency")}</Typography>
                        <CurrencyPicker/>
                    </Stack>
                </ListItem>
                <ListDivider/>
                <ListItem>
                    <Stack spacing={1}>
                        <Typography level="title-sm">{t("language")}</Typography>
                        <LanguagePicker/>
                    </Stack>
                </ListItem>
                <ListDivider/>
            </List>
        </Stack>
    )
}

export default SettingsPage;
