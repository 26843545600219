import {Children, cloneElement, FC, ReactNode} from "react";
import {Grid, Typography} from "@mui/joy";
import {SxProps} from "@mui/joy/styles/types";
import {GridSize} from "@mui/system/Unstable_Grid/GridProps";

export interface ChildrenGrid {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    md?: boolean | GridSize;
    lg?: boolean | GridSize;
    xl?: boolean | GridSize;
    alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
    justifyContent?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly";
}

interface Props {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    md?: boolean | GridSize;
    lg?: boolean | GridSize;
    xl?: boolean | GridSize;
    childrenGrid?: ChildrenGrid;
    sx?: SxProps;
    spacing?: number;
    children?: ReactNode;
}

const EqualGridContainer: FC<Props> = (
    {
        xs,
        sm,
        md,
        xl,
        lg,
        childrenGrid,
        children,
        sx,
        spacing,
    }
    ) => {
    const arrayChildren = Children.toArray(children);

    return (
        <Grid
            xs={xs} sm={sm} md={md} lg={lg} xl={xl}
            container
            spacing={spacing}
            sx={{...sx, flexGrow: 1}}
        >
            {arrayChildren.map((child, index) => {
                return (
                    <Grid
                        key={index}
                        display="flex"
                        xs={childrenGrid?.xs}
                        sm={childrenGrid?.sm}
                        md={childrenGrid?.md}
                        lg={childrenGrid?.lg}
                        xl={childrenGrid?.xl}
                        alignItems={childrenGrid?.alignItems}
                        justifyContent={childrenGrid?.justifyContent}
                    >
                        {child}
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default EqualGridContainer;
