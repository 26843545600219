import {CSSProperties, FC} from "react";
import Button from "@mui/joy/Button";
import {AddPhotoAlternate} from "@mui/icons-material";

interface Props {
    src?: string | null;
    onClick: () => void;
}

const ImageButton: FC<Props> = ({src, onClick}) => {
    return (
        <Button color='neutral' fullWidth={true} sx={{width: "50px", height: "50px", borderRadius: 8, overflow: 'hidden', padding: 0}}
                onClick={onClick}
        >
            {src ? <img src={src} alt='' style={{
                width: '50px',
                height: '50px',
                objectFit: 'cover',
                padding: 0
            }}/> : <AddPhotoAlternate style={{width: '50%', height: '50%'}}/>}
        </Button>
    )
}

export default ImageButton;
