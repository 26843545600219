import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import '@fontsource/inter';
import Parse from 'parse';
import {Configs} from "./Configs";
import {ParseConfigs} from "./.env";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from './Localization/Translations.json';
import './index.css';

i18n
    .use(initReactI18next)
    .init({
        resources: translations,
        lng: window.navigator.language,
        fallbackLng: "tr",
        interpolation: {
            escapeValue: false
        }
    });

Parse.initialize(Configs.appId, ParseConfigs.javascriptKey);
Parse.serverURL = ParseConfigs.serverURL;

import App from './App';
import {Provider} from "react-redux";
import store from "./Redux/Store";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
