import {CategoryType} from "../Models/Category/CategoryType";
import {convertCategory, convertCategoryProducts} from "./Converters";
import Parse from "parse";
import {RootState} from "../Redux/Store";
import {ProductType} from "../Models/Product/ProductType";
import {CategoryWithCategoryProducts} from "../Models/Category/CategoryWithCategoryProducts";
import {ThrowHandler} from "../ThrowHandler";

const save = async (category: CategoryType, productIds: string[]): Promise<CategoryWithCategoryProducts> => {
    const response = await ThrowHandler.tryFunction(Parse.Cloud.run("saveCategory", {category, productIds}));
    const categoryParse = response.category;
    const categoryProductObjects = response.categoryProducts;
    const categoryWithCategoryProducts: CategoryWithCategoryProducts = {
        category: convertCategory(categoryParse),
        categoryProducts: convertCategoryProducts(categoryProductObjects),
    }
    return categoryWithCategoryProducts;
}

const saveIndexes = async (categoryIds: string[]) => {
    await Parse.Cloud.run("saveCategoryIndexes", {categoryIds});
}

const getProducts = async (categoryId: string, state: RootState) => {
    const products = state.product.data;
    const categoryProducts = state.categoryProduct.data;

    const categoryProductsForCategory = categoryProducts.filter(categoryProduct => categoryProduct.categoryId === categoryId);
    categoryProductsForCategory.sort((a, b) => a.index - b.index);
    const productsInCategory: ProductType[] = [];
    categoryProductsForCategory.forEach(categoryProduct => {
        const product = products.find(product => product.id === categoryProduct.productId);
        if (product) {
            productsInCategory.push(product);
        }
    })
    return productsInCategory;
}

const deleteCategories = async (categoryIds: string[]) => {
    return await ThrowHandler.tryFunction(Parse.Cloud.run("deleteCategories", {categoryIds}));
}

export const CategoryApi = {
    save,
    saveIndexes,
    getProducts,
    deleteCategories
}

