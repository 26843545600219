import {FC} from "react";
import {Checkbox, Stack, Typography} from "@mui/joy";
import {checkIsMobile} from "../../../Helpers/CommonHelpers";
import {useTranslation} from "react-i18next";

interface Props {
    isEditing?: boolean;
    selectionDisabled: boolean;
    selectionChecked: boolean;
    onSelectionChange: (event: any) => void;
}

const ProductsTableHeader: FC<Props> = ({isEditing, selectionDisabled, selectionChecked, onSelectionChange}) => {
    const {t} = useTranslation();
    const isMobile = checkIsMobile();

    return (
        <thead>
        <tr>
            {isEditing && (
                <th style={{width: isMobile ? "30px" : "40px"}}></th>
            )}
            <th style={{width: isMobile ? "30px" : "40px"}}>
                <Stack direction="row">
                    <Checkbox disabled={selectionDisabled}
                              checked={selectionChecked}
                              onChange={onSelectionChange}/>
                </Stack>
            </th>
            <th style={{whiteSpace: 'pre-line'}}>
                <Typography textAlign="start" level="title-sm">{t("productsPage.product")}</Typography>
            </th>
            <th style={{whiteSpace: 'pre-line'}}>
                <Typography textAlign="start" level="title-sm">{t("description")}</Typography>
            </th>
            <th style={{whiteSpace: 'pre-line'}}>
                <Typography textAlign="start" level="title-sm">{t("price")}</Typography>
            </th>
            <th style={{whiteSpace: 'pre-line'}}>
                <Typography textAlign="start" level="title-sm">{t("productsPage.stockStatus")}</Typography>
            </th>
        </tr>
        </thead>
    )
}

export default ProductsTableHeader;
