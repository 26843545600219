import {useAppDispatch} from "../../../Redux/Hooks";
import {getProducts, saveCategory, setData, updateData} from "../../../Slices/Category/EditCategorySlice";
import {CategoryType} from "../../../Models/Category/CategoryType";

export default () => {
    const dispatch = useAppDispatch();

    const handleData = (category: CategoryType | null) => {
        dispatch(setData(category));
        if (category) {
            dispatch(getProducts(category.id));
        }
    }

    const save = () =>{
        dispatch(saveCategory());
    }

    const handleCategoryChange = (category: CategoryType) => {
        dispatch(updateData(category));
    }

    return [handleData, save, handleCategoryChange] as const;
}
