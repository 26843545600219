import {useEffect, useState} from "react";
import {RestaurantType} from "../../Models/RestaurantType";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import {resetStatus, saveRestaurant} from "../../Slices/Restaurant/RestaurantSlice";

export default () => {
    const [formData, setFormData] = useState<RestaurantType>({
        id: "0",
        name: "",
        description: "",
        image: {
            src: null,
            isNew: false
        },
        status: "pending",
        userId: "",
        currency: "TRY",
        language: "tr",
    });
    const dispatch = useAppDispatch();
    const restaurant = useAppSelector(state => state.restaurant).data;
    const user = useAppSelector(state => state.user).user;

    useEffect(() => {
        setFormData({
            ...formData,
            ...restaurant,
            language: user?.locale ?? "tr",
        })
    }, [restaurant, user?.locale]);

    const handleFormDataChange = (e: any) => {
        const {id, value} = e.target;
        setFormData({...formData, [id]: value});
    }

    const handleImageChange = (src: string | null) => {
        setFormData({...formData, image: {src: src, isNew: true}});
    }

    const handleSave = () => {
        if (formData.name !== formData.name) {
            alert("Passwords do not match!");
            return;
        }
        dispatch(saveRestaurant(formData));
    }

    const handleResetStatus = () => {
        dispatch(resetStatus());
    }

    return [formData, handleFormDataChange, handleImageChange, handleSave, handleResetStatus] as const;
}
