import React, {useEffect, useState} from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import MainPage from "./Pages/MainPage";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import WelcomePage from "./Pages/WelcomePage";
import RegisterPage from "./Pages/Register/RegisterPage";
import LoginPage from "./Pages/Login/LoginPage";
import {useAppDispatch, useAppSelector} from "./Redux/Hooks";
import {useNavigate} from "react-router";
import {setUser} from "./Slices/User/UserSlice";
import {homeNavigationItem} from "./Pages/Drawer/NavigationItem";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import myTheme from "./Theme/MyTheme";

declare module '@mui/joy/styles' {
    // noinspection JSUnusedGlobalSymbols
    interface TypographySystemOverrides {
        'title-xs': true;
    }
}

function App() {
    return (
      <CssVarsProvider theme={myTheme}>
          <main>
              <BrowserRouter>
                  <MainRoute/>
              </BrowserRouter>
          </main>
      </CssVarsProvider>
  );
}

const MainRoute = () => {
    const userState = useAppSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        setPageLoaded(true);
        dispatch(setUser());
    }, []);

    useEffect(() => {
        if (!pageLoaded) {
            return;
        }
        if (userState.user) {
            if (!location.pathname.startsWith("/" + homeNavigationItem.key)) {
                navigate('/' + homeNavigationItem.key);
            }
        } else {
            switch (location.pathname) {
                case '/register':
                case '/login':
                    break;
                default:
                    navigate('/');
            }
        }
    }, [userState.user, pageLoaded]);

    return (
        <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/home" element={<MainPage />} />
        </Routes>
    );
}

export default App;
