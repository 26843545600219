import {useEffect, useState} from "react";
import {DefaultModel} from "../Models/BaseModel";
import {Checkbox, IconButton, List, ListItem, ListItemButton, ListItemContent, Stack} from "@mui/joy";
import {Close, InsertPhoto} from "@mui/icons-material";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import useMyTheme from "../Theme/useMyTheme";
import {useTranslation} from "react-i18next";

interface DataPickerProps<T extends DefaultModel> {
    pickedData: T[],
    data: T[],
    title: string,
    onDone?: (data: T[]) => void
    onClose?: () => void
}

const DataPicker = <T extends DefaultModel>({pickedData, data, title, onDone, onClose}: DataPickerProps<T>) => {
    const [dataForPicker, setDataForPicker] = useState<T[]>(data);
    const [pickedDataForPicker, setPickedDataForPicker] = useState<T[]>(pickedData);
    const [filteredDataForPicker, setFilteredDataForPicker] = useState<T[]>(dataForPicker);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { myTheme } = useMyTheme();
    const {t} = useTranslation();

    useEffect(() => {
        setDataForPicker(data);
        refresh();
    }, [data]);

    useEffect(() => {
        setPickedDataForPicker(pickedData);
        refresh();
    }, [pickedData]);

    useEffect(() => {
        refresh();
    }, [searchTerm]);

    const handleSelect = (data: T) => {
        if (pickedDataForPicker.includes(data)) {
            setPickedDataForPicker(pickedDataForPicker.filter(item => item !== data));
        } else {
            setPickedDataForPicker([...pickedDataForPicker, data]);
        }
        refresh();
    }

    const isItemPicked = (item: T) => {
        const foundItem = pickedDataForPicker.find((pickedItem) => {
            return pickedItem.id === item.id;
        });
        return foundItem !== undefined;
    }

    const refresh = () => {
        if (searchTerm === "") {
            setFilteredDataForPicker(dataForPicker);
        } else {
            const filteredData = dataForPicker.filter((item) => {
                return item.name.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setFilteredDataForPicker(filteredData);
        }
    }

    const handleDone = () => {
        if (onDone) {
            onDone(pickedDataForPicker);
        }
    }
    return (
        <Stack dir={myTheme.direction} spacing={2} sx={{minWidth: "30vw", maxHeight: "60vh", overflow: "hidden"}}>
            <Stack
                direction="row"
                justifyContent="space-between"
            >
                <IconButton onClick={onClose}>
                    <Close/>
                </IconButton>
                <Button onClick={handleDone}>Done</Button>
            </Stack>
            <h1>{title}</h1>
            <p>{t("pleaseChoose")}</p>
            <Input placeholder="Search..." onChange={(e: any) => setSearchTerm(e.target.value)}/>
            <List sx={{overflow: "auto"}}>
                {filteredDataForPicker.map((item) => {
                    return (
                        <ListItem key={item.id}>
                            <ListItemButton onClick={() => handleSelect(item)}>
                                <ListItemContent
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <div style={{width: "25px", height: "25px", borderRadius: 4, overflow: "hidden"}}>
                                                {item.image.src ? <img src={item.image.src ?? undefined} alt='' style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        padding: 0
                                                    }}/> :
                                                <InsertPhoto style={{width: '100%', height: '100%'}}/>
                                                }
                                            </div>
                                            <p>{item.name}</p>
                                        </Stack>
                                        <Checkbox checked={isItemPicked(item)}></Checkbox>
                                    </Stack>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Stack>
    );
}

export default DataPicker;
