import {FC, useEffect, useState} from "react";
import Input from "@mui/joy/Input";
import {Currency, formatPrice} from "../Models/Currency";
import {stringToDecimal} from "../Helpers/StringToDecimal";

interface Props {
    id?: string;
    placeholder?: string;
    value: number;
    onChange: (e: any) => void;
}

const CurrencyInput: FC<Props> = ({id, placeholder, value, onChange}) => {
    const [currency, setCurrency] = useState<Currency>("TRY");
    const [currentValue, setCurrentValue] = useState<string>(formatPrice(value, currency) );
    let number = value;
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (isLoaded) {
            return;
        }
        setCurrentValue(formatPrice(value, currency));
        number = value;
        setIsLoaded(true);
    }, [value]);

    const handleValueChange = (e: any) => {
        let targetValue: string = e.target.value;
        const trimmedValue = stringToDecimal(targetValue, currency);
        const changedNumber = Number(trimmedValue);
        number = changedNumber;
        onChange({target: {id, value: changedNumber}});
        setCurrentValue(targetValue);
    }

    const handleOnBlur = (e: any) => {
        let targetValue = e.target.value;
        const trimmedValue = stringToDecimal(targetValue, currency);
        const changedNumber = Number(trimmedValue);
        setCurrentValue(formatPrice(changedNumber, currency));
    }

    return (
        <Input value={currentValue} placeholder={placeholder} onChange={handleValueChange} onBlur={handleOnBlur}>
            CurrencyInput
        </Input>
    )
}

export default CurrencyInput;
