import {AdjustPriceState} from "../Models/AdjustPriceState";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../Redux/Store";
import {ProductApi} from "../Api/Product";
import {ProductWithPrice} from "../Models/Product/ProductWithPrice";

const initialState: AdjustPriceState = {
    status: 'idle',
    error: undefined,
    categories: [],
    adjustPriceType: "increase",
    roundType: "none",
    adjustPercent: 0,
    isAdjusted: false
}

export const adjustPrices = createAsyncThunk(
    'adjustPrice/adjustPrices',
    async (_, {getState}) => {
        const state = getState() as RootState;
        return await ProductApi.adjustPrices(state);
    }
)

export const updatePrices = createAsyncThunk(
    'adjustPrice/updatePrices',
    async (_, {getState}) => {
        const state = (getState() as RootState).adjustPrice;
        const categories = state.categories;
        const productsWithPrice: ProductWithPrice[] = [];
        categories.forEach(category => {
            category.products.forEach(product => {
                if (productsWithPrice.find(p => p.id === product.id) === undefined) {
                    const productWithPrice: ProductWithPrice = {
                        id: product.id,
                        price: product.adjustedPrice,
                    }
                    productsWithPrice.push(productWithPrice);
                }
            })
        });
        return await ProductApi.updatePrices(productsWithPrice);
    }
)

const adjustPriceSlice = createSlice({
    name: "adjustPrice",
    initialState,
    reducers: {
        setAdjustPriceType: (state, action: PayloadAction<AdjustPriceState["adjustPriceType"]>) => {
            state.adjustPriceType = action.payload;
        },
        setRoundType: (state, action: PayloadAction<AdjustPriceState["roundType"]>) => {
            state.roundType = action.payload;
        },
        setAdjustPercent: (state, action: PayloadAction<AdjustPriceState["adjustPercent"]>) => {
            state.adjustPercent = action.payload;
        },
        setIsAdjusted: (state, action: PayloadAction<AdjustPriceState["isAdjusted"]>) => {
            state.isAdjusted = action.payload;
        },
        reset(state) {
            state.status = initialState.status;
            state.error = initialState.error;
            state.adjustPercent = initialState.adjustPercent;
            state.adjustPriceType = initialState.adjustPriceType;
            state.roundType = initialState.roundType;
            state.isAdjusted = initialState.isAdjusted;
        },
        resetStatus(state) {
            state.status = initialState.status;
            state.error = initialState.error;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(adjustPrices.fulfilled, (state, action) => {
                state.categories = action.payload;
            })
            .addCase(updatePrices.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(updatePrices.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(updatePrices.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const {
    setAdjustPriceType,
    setRoundType,
    setAdjustPercent,
    setIsAdjusted,
    reset,
    resetStatus
} = adjustPriceSlice.actions;

export default adjustPriceSlice.reducer;
