import {createPageInfo, PageInfo} from "./PageInfo";
import {ApiStatusType} from "./ApiStatusType";
import {BaseModel} from "./BaseModel";
import {arrayMove} from "@dnd-kit/sortable";

type Filters = {
    [key: string]: any;
    searchTerm: string;
}

export type DataState<T extends BaseModel> = {
    data: T[];
    filteredData: T[];
    dataOnCurrentPage: T[];
    selectedData: T[];
    pageInfo: PageInfo;
    status: ApiStatusType;
    filters: Filters | null;
    error: string | undefined;
}

export const setPageInfo = (state: DataState<any>) => {
    const data = state.filters === null ? state.data : state.filteredData;
    const total = Math.ceil(data.length / state.pageInfo.dataCountPerPage);
    let currentPage = state.pageInfo.currentPage;
    if (currentPage >= total) {
        currentPage = total - 1;
        if (currentPage < 0) {
            currentPage = 0;
        }
    }
    state.pageInfo = {
        ...state.pageInfo,
        total: total,
        currentPage: currentPage
    }
}

export const setDataOnCurrentPage = (state: DataState<any>) => {
    const data = state.filters === null ? state.data : state.filteredData;
    const start = state.pageInfo.currentPage * state.pageInfo.dataCountPerPage;
    const end = start + state.pageInfo.dataCountPerPage;
    state.dataOnCurrentPage = data.slice(start, end);
}

export const createFiltersIfNotExist = (state: DataState<any>) => {
    if (state.filters === null) {
        createEmptyFilters(state);
    }
}

export const createEmptyFilters = (state: DataState<any>) => {
    state.filters = {
        searchTerm: '',
    }
}

export const filterData = (state: DataState<any>, shouldResetPageInfo: boolean = true) => {
    if (shouldResetPageInfo) {
        state.pageInfo = createPageInfo();
    }
    if (state.filters) {
        const isThereAnyFilter = state.filters.searchTerm.length !== 0 || Object.keys(state.filters).length > 1
        if (isThereAnyFilter) {
            let filteredData = state.data.filter(item => {
                let result = true;
                for (const key in state.filters) {
                    if (key === 'searchTerm' || key === "categoryId") {
                        continue;
                    }
                    if (item[key] !== state.filters[key]) {
                        result = false;
                        break;
                    }
                }
                return result;
            });
            if (state.filters.searchTerm !== '') {
                filteredData = filteredData.filter(item => item.name.toLowerCase().includes(state.filters!.searchTerm.toLowerCase()));
            }
            state.filteredData = filteredData;
        } else {
            state.filters = null;
            state.filteredData = state.data;
        }
    } else {
        state.filteredData = state.data;
    }
    state.selectedData = state.selectedData.filter(p => state.filteredData.find(p2 => p2.id === p.id) !== undefined);
    setPageInfo(state);
    setDataOnCurrentPage(state);
}

export const saveData = <T extends BaseModel>(newData: T, state: DataState<T>) => {
    let index = state.data.findIndex(item => item.id === newData.id);
    if (index === -1) {
        state.data.unshift(newData);
    } else {
        state.data[index] = newData;
    }
    filterData(state, false);
}

export const deleteData = <T extends BaseModel>(datasToDelete: T[], state: DataState<T>) => {
    state.data = state.data.filter(category => !datasToDelete.find(p => p.id === category.id));
    filterData(state, false);
}

export const handleDataSelectionChange = <T extends BaseModel>(data: T[], isSelected: boolean, state: DataState<T>) => {
    if (isSelected) {
        state.selectedData = [...state.selectedData, ...data.filter(p => state.selectedData.find(p2 => p2.id === p.id) === undefined)];
    } else {
        state.selectedData = state.selectedData.filter(p => data.find(p2 => p2.id === p.id) === undefined);
    }
}
