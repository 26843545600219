import {useAppDispatch} from "../../Redux/Hooks";
import {useState} from "react";
import {login, resetStatus} from "../../Slices/User/UserSlice";

type LoginForm = {
    email: string;
    password: string;
}

export default () => {
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<LoginForm>({
        email: "",
        password: "",
    });

    const handleFormDataChange = (e: any) => {
        const {id, value} = e.target;
        setFormData({...formData, [id]: value});
    }

    const handleLogin = () => {
        dispatch(login(formData));
    }

    const handleResetStatus = () => {
        dispatch(resetStatus());
    }

    return [formData, handleFormDataChange, handleLogin, handleResetStatus] as const;
}
