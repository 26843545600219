export type LanguageCode = "tr" | "en" | "ar";
export const languages: LanguageCode[] = ["tr", "en", "ar"];
export const getLanguageName = (code: LanguageCode): string => {
    switch (code) {
        case "tr":
            return "Türkçe";
        case "en":
            return "English";
        case "ar":
            return "العربية";
    }
}
