import Button from "@mui/joy/Button";
import {Stack} from "@mui/joy";
import {DefaultModel} from "../Models/BaseModel";
import {useTranslation} from "react-i18next";

interface DataPickerButtonProps<T extends DefaultModel> {
    title: string;
    pickedData: T[];
    onClick: () => void;
}

const DataPickerButton = <T extends DefaultModel>({title, pickedData, onClick}: DataPickerButtonProps<T>) => {
    const {t} = useTranslation();

    return <Stack direction={"row"} spacing={1} justifyContent="space-between" alignItems="center" sx={{width: "100%"}}>
            <Stack spacing={0} alignItems="flex-start">
                <p style={{color: "black", margin: "0 0 0 0"}}>{title}</p>
                <Stack direction={"row"} spacing={1}>
                    {pickedData.map((item) => {
                        return <p key={item.id} style={{color: "grey", fontSize: 11, margin: "0 4px 0 0"}}>{item.name}</p>
                    })}
                </Stack>
            </Stack>
            <Button variant="plain" onClick={onClick}>
                {t("choose")}
            </Button>
        </Stack>
}

export default DataPickerButton;
