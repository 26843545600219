import {FC, useEffect, useRef} from "react";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import ImagePicker from "../../Components/ImagePicker";
import {Box, Stack} from "@mui/joy";
import {checkIsMobile} from "../../Helpers/CommonHelpers";
import useProfilePageHooks from "./useProfilePageHooks";
import {useTranslation} from "react-i18next";

const ProfilePage: FC = () => {
    const isMobile = checkIsMobile();
    const formRef = useRef<HTMLFormElement>(null);
    const [formData, handleFormDataChange, handleImageChange, handleSave, handleResetStatus] = useProfilePageHooks();
    const {t} = useTranslation();

    useEffect(() => {
        return () => {
            handleResetStatus();
        }
    }, []);

    const handleSubmit = (e: any) => {
        e.preventDefault(); // This will always prevent the page refresh
        if (formRef.current && !formRef.current.reportValidity()) {
            return;
        }
        handleSave();
    }

    return (
        <Box width={isMobile ? "90%" : "50%"} m={2.5}>
            <form ref={formRef}>
                <Stack spacing={2}>
                    <Input
                        id="name"
                        value={formData.name}
                        type={"text"}
                        placeholder={t("signUpPage.restaurantName")}
                        required
                        onChange={handleFormDataChange}
                    />
                    <Input
                        id="description"
                        value={formData.description ?? ""}
                        type={"text"}
                        placeholder={t("signUpPage.restaurantDescription")}
                        onChange={handleFormDataChange}
                    />
                    <ImagePicker src={formData.image.src} onFileChange={handleImageChange}/>
                    <Button type="button" onClick={handleSubmit}>
                        {t("save")}
                    </Button>
                </Stack>
            </form>
         </Box>
    );
}

export default ProfilePage;
