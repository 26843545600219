import {FC} from "react";
import {Box, Checkbox, Grid, Stack, Typography} from "@mui/joy";
import ImageButton from "../../../Components/ImageButton";
import {ProductType} from "../../../Models/Product/ProductType";
import {formatPrice} from "../../../Models/Currency";
import {useAppSelector} from "../../../Redux/Hooks";

interface Props {
    product: ProductType;
    isSelected: boolean;
    onClick: (e: any) => void;
    onSelectChange: (e: any) => void;
}

const ProductRow: FC<Props> = ({product, isSelected, onClick, onSelectChange}) => {
    const currency = useAppSelector(state => state.restaurant.data?.currency ?? "TRY");

    return (
        <tr onClick={onClick}>
            <td>
                <Checkbox
                    checked={isSelected}
                    onChange={onSelectChange}
                    onClick={e => e.stopPropagation()}
                />
            </td>
            <td valign='middle'>
                    <Grid container sx={{flexGrow: 1}} justifyContent="flex-start">
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={3}
                            display="flex"
                            alignItems="center"
                        >
                            <ImageButton src={product.image.src} onClick={() => {
                            }}/>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={9}
                            display="flex"
                            alignItems="center"
                        >
                        <Typography level="body-xs">{product.name}</Typography>
                        </Grid>
                    </Grid>
            </td>
            <td>
                <Typography level="body-xs">{product.description}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{formatPrice(product.price, currency)}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{product.available ? "Stok Var" : "Stok Yok"}</Typography>
            </td>
        </tr>
    )
}

export default ProductRow;
