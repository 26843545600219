import {CSSProperties, FC, ReactNode} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

interface Props {
    id: string | number;
    onClick: (e: any) => void;
    style?: CSSProperties;
    children?: ReactNode;
}

const SortableRow: FC<Props> = ({id, onClick, style, children}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: id});

    const thisStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const rowStyle = style ? {...style, ...thisStyle} : thisStyle;

    return (
        <tr ref={setNodeRef} style={rowStyle} {...attributes} {...listeners} onClick={
            (e) => {
                onClick(e)
            }
        }>
            { children }
        </tr>
    )
}

export default SortableRow;
