import {useAppDispatch} from "../Redux/Hooks";
import {useState} from "react";
import {login, requestPasswordReset, resetStatus} from "../Slices/User/UserSlice";

export default () => {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>("");

    const handleEmailInput = (e: any) => {
        const {id, value} = e.target;
        setEmail(value);
    }

    const handleRequestPassword = () => {
        dispatch(requestPasswordReset(email));
    }

    const handleResetStatus = () => {
        dispatch(resetStatus());
    }

    return [email, handleEmailInput, handleRequestPassword, handleResetStatus] as const;
}
