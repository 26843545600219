import {FC, useEffect, useRef, useState} from "react";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import {Box, Stack} from "@mui/joy";
import {Link as RouterLink} from "react-router-dom";
import LinkButton from "../../Components/LinkButton";
import useLoginPageHooks from "./useLoginPageHooks";
import {resetStatus} from "../../Slices/User/UserSlice";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import InfoBanner from "../../Components/InfoBanner";
import {useTranslation} from "react-i18next";

const LoginPage: FC = () => {
    const error = useAppSelector(state => state.user).error;
    const formRef = useRef<HTMLFormElement>(null);
    const [formData, handleFormDataChange, handleLogin, handleResetStatus] = useLoginPageHooks();
    const {t} = useTranslation();

    const handleBannerClose = () => {
        handleResetStatus();
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (formRef.current && !formRef.current.reportValidity()) {
            return;
        }
        handleLogin();
    }

    return (
        <Stack sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
        }}>
            <Box sx={{width: "50%", heigth: "50%"}}>
                <h1 style={{textAlign: "center"}}>
                    {t("login.title")}
                </h1>
                <form ref={formRef}>
                    <Stack spacing={2}>
                        <Input
                            id="email"
                            value={formData.email}
                            type={"email"}
                            placeholder={t("email")}
                            required
                            onChange={handleFormDataChange}
                        />
                        <Input
                            id="password"
                            value={formData.password}
                            type={"password"}
                            placeholder={t("password")}
                            required
                            onChange={handleFormDataChange}
                        />
                        <Button type="button" onClick={handleSubmit}>
                            {t("login.loginButtonTitle")}
                        </Button>
                        <Stack spacing={-0.025} alignItems="center">
                            <LinkButton component={RouterLink} to="/forgot-password">
                                {t("login.forgotPassword")}
                            </LinkButton>
                        </Stack>
                        <Stack spacing={0.5} alignItems="center">
                            <p style={{margin: 0}}>{t("login.noAccount")}</p>
                            <LinkButton component={RouterLink} to="/register">
                                {t("signUp.signUpButtonTitle")}
                            </LinkButton>
                        </Stack>
                    </Stack>
                </form>
            </Box>
            <InfoBanner
                open={error !== undefined}
                type="error"
                message={error}
                onClose={handleBannerClose}
            />
        </Stack>
    );
}

export default LoginPage;
