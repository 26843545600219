import {useAppDispatch, useAppSelector} from "../../../Redux/Hooks";
import {RootState} from "../../../Redux/Store";
import {
    changePage, deleteSelectedCategories,
    handleSelectionChange, updateIndexes
} from "../../../Slices/Category/CategorySlice";
import {CategoryType} from "../../../Models/Category/CategoryType";

export default () => {
    const categoryState = useAppSelector((state: RootState) => state.category);
    const categories = categoryState.dataOnCurrentPage;
    const selectedCategories = categoryState.selectedData;

    const dispatch = useAppDispatch();

    const handleAllCategorySelection = (event: any) => {
        dispatch(handleSelectionChange({categories, isSelected: event.target.checked}));
    }

    const handleCategorySelection = (event: any, category: CategoryType) => {
        dispatch(handleSelectionChange({categories: [category], isSelected: event.target.checked}));
    }

    const deleteSelected = () => {
        dispatch(deleteSelectedCategories());
    }

    const isCategorySelected = (category: CategoryType) => {
        return selectedCategories.find(p => p.id === category.id) !== undefined;
    }

    const selectPage = (page: number) => {
        dispatch(handleSelectionChange({categories: selectedCategories, isSelected: false}));
        dispatch(changePage(page));
    }

    const handleIndexesOnCurrentPage = (oldIndex: number, newIndex: number) => {
        dispatch(updateIndexes({oldIndex, newIndex}));
    }

    return [handleAllCategorySelection, handleCategorySelection, deleteSelected, isCategorySelected, selectPage, handleIndexesOnCurrentPage] as const;
}
