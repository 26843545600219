import {FC, useEffect, useState} from "react";
import {Grid, Modal, ModalDialog, Stack} from "@mui/joy";
import Input from "@mui/joy/Input";
import {Search} from "@mui/icons-material";
import Button from "@mui/joy/Button";
import {useAppDispatch, useAppSelector} from "../../Redux/Hooks";
import {resetFilters, resetStatus, search} from "../../Slices/Category/CategorySlice";
import CategoriesTable from "./Table/CategoriesTable";
import CreateEditCategoryPage from "./Edit/CreateEditCategoryPage";
import {CategoryType} from "../../Models/Category/CategoryType";
import {reset} from "../../Slices/Category/EditCategorySlice";
import InfoBanner from "../../Components/InfoBanner";
import EqualGridContainer from "../EqualGridContainer";
import {useTranslation} from "react-i18next";

const CategoriesPage: FC = () => {
    const categoryState = useAppSelector(state => state.category);
    const filters = categoryState.filters;
    const status = categoryState.status;
    const error = categoryState.error;
    const [searchTerm, setSearchTerm] = useState<string>("");
    const dispatch = useAppDispatch();
    const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState<boolean>(false);
    const [categoryToEdit, setCategoryToEdit] = useState<CategoryType | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const {t} = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(resetStatus());
        }
    }, []);

    useEffect(() => {
        if (status === "succeeded" || status === "failed") {
            setShowBanner(true);
        }
    }, [status]);

    const handleBannerClose = () => {
        setShowBanner(false);
        dispatch(resetStatus());
    }

    const getBannerType = () => {
        if (status === "succeeded") {
            return "success";
        } else if (status === "failed") {
            return "error";
        }
    }

    const searchCategory = (event: any) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        dispatch(search(searchTerm));
    }

    const openCategoryEditPage = (category: CategoryType) => {
        setCategoryToEdit(category);
        setOpenAddCategoryDialog(true);
    }

    const closeCategoryEditPage = () => {
        setCategoryToEdit(null);
        setOpenAddCategoryDialog(false);
        dispatch(reset());
    }

    const changeEditingStatus = () => {
        dispatch(resetFilters());
        setIsEditing(!isEditing)
    }

    return (
        <Stack width="100%" height="100%">
            <Grid
                container
                alignItems="center"
                direction="row"
                sx={{width: "100%", pt: 1, pl: 1}}
            >
                <Grid
                    xs={6}
                    sm={6}
                    md={9}
                    m={0}
                    p={0}
                    pr={1}
                >
                    {!isEditing &&
                        <Input fullWidth value={searchTerm} placeholder={t("search")} startDecorator={<Search/>}
                               onChange={searchCategory}/>
                    }
                </Grid>
                <EqualGridContainer xs={6} sm={6} md={3} spacing={1} childrenGrid={{xs: 6}}>
                    {filters === null && <Button fullWidth={true} variant="outlined" onClick={changeEditingStatus}>{isEditing ? t("ok") : t("edit")}</Button>}
                    <Button fullWidth={true} onClick={() => setOpenAddCategoryDialog(true)}>{t("categoriesPage.addCategory")}</Button>
                </EqualGridContainer>
            </Grid>
            <CategoriesTable
                isEditing={isEditing}
                onClick={(category) => openCategoryEditPage(category)}
            />
            <Modal open={openAddCategoryDialog} onClose={() => closeCategoryEditPage()}>
                <ModalDialog>
                    <CreateEditCategoryPage categoryToEdit={categoryToEdit} onClose={() => {
                        closeCategoryEditPage();
                    }}/>
                </ModalDialog>
            </Modal>
            <InfoBanner
                open={showBanner}
                type={getBannerType()}
                message={error}
                onClose={handleBannerClose}
            />
        </Stack>
    );
}

export default CategoriesPage;
