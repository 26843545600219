import {convertCategoryProducts, convertProduct, convertProducts} from "./Converters";
import Parse from "parse";
import {RootState} from "../Redux/Store";
import {ProductType} from "../Models/Product/ProductType";
import {ProductWithCategoryProducts} from "../Models/Category/CategoryWithCategoryProducts";
import {CategoryType} from "../Models/Category/CategoryType";
import {ThrowHandler} from "../ThrowHandler";
import {ProductWithPrice} from "../Models/Product/ProductWithPrice";
import {ProductWithAdjustedPrice} from "../Models/Product/ProductWithAdjustedPrice";
import {CategoryWithAdjustedPrices} from "../Models/Category/CategoryWithAdjustedPrices";

const save = async (product: ProductType, categoryIds: string[]): Promise<ProductWithCategoryProducts> => {
    const response = await ThrowHandler.tryFunction(Parse.Cloud.run("saveProduct", {product, categoryIds}));
    const productParse = response.product;
    const categoryProductObjects = response.categoryProducts;
    const productWithCategoryProducts: ProductWithCategoryProducts = {
        product: convertProduct(productParse),
        categoryProducts: convertCategoryProducts(categoryProductObjects),
    }
    return productWithCategoryProducts;
}

const getCategories = async (productId: string, state: RootState) => {
    const categories = state.category.data;
    const categoryProducts = state.categoryProduct.data;

    const categoryProductsForProduct = categoryProducts.filter(categoryProduct => categoryProduct.productId === productId);
    categoryProductsForProduct.sort((a, b) => a.index - b.index);
    const categoriesInProduct: CategoryType[] = [];
    categoryProductsForProduct.forEach(categoryProduct => {
        const category = categories.find(category => category.id === categoryProduct.categoryId);
        if (category) {
            categoriesInProduct.push(category);
        }
    })
    return categoriesInProduct;
}

const deleteProducts = async (productIds: string[]) => {
    return await ThrowHandler.tryFunction(Parse.Cloud.run("deleteProducts", {productIds}));
}

const adjustPrices = async (state: RootState) => {
    const categories = state.category.data;
    const products = state.product.data;
    const categoryProducts = state.categoryProduct.data;
    return categories.map(category => {
        const filteredCategoryProducts = categoryProducts.filter(categoryProduct => categoryProduct.categoryId === category.id);
        const productsWithAdjustedPrices: ProductWithAdjustedPrice[] = [];
        filteredCategoryProducts.forEach(categoryProduct => {
            const product = products.find(product => product.id === categoryProduct.productId)
            if (product) {
                let adjustPercent = 0
                switch (state.adjustPrice.adjustPriceType) {
                    case "increase":
                        adjustPercent = state.adjustPrice.adjustPercent;
                        break;
                    case "decrease":
                        adjustPercent = state.adjustPrice.adjustPercent * -1;
                        break;
                }
                let newPrice = product.price * (1 + adjustPercent / 100);
                switch (state.adjustPrice.roundType) {
                    case "none":
                        break;
                    case "upwards":
                        newPrice = Math.ceil(newPrice);
                        break;
                    case "downwards":
                        newPrice = Math.floor(newPrice);
                        break;
                    case "upwards99":
                        newPrice = Math.ceil(newPrice) - 0.01;
                        break;
                }
                const productWithAdjustedPrice: ProductWithAdjustedPrice = {
                    id: product.id,
                    name: product.name,
                    originalPrice: product.price,
                    adjustedPrice: newPrice,
                }
                productsWithAdjustedPrices.push(productWithAdjustedPrice);
            }
        });
        const categoryWithAdjustedPrices: CategoryWithAdjustedPrices = {
            id: category.id,
            name: category.name,
            products: productsWithAdjustedPrices
        }
        return categoryWithAdjustedPrices;
    });
}

const updatePrices = async (products: ProductWithPrice[]) => {
    const response: Parse.Object[] = await ThrowHandler.tryFunction(Parse.Cloud.run("updatePrices", {products}));
    const newProducts = convertProducts(response);
    return newProducts
}

export const ProductApi = {
    save,
    getCategories,
    deleteProducts,
    adjustPrices,
    updatePrices,
}

