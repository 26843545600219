import {useTheme} from "@mui/joy";
import {MyTheme} from "./MyTheme";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useAppSelector} from "../Redux/Hooks";
import {convertLocaleToLanguage} from "../Api/Converters";

export default () => {
    const myTheme = useTheme() as MyTheme;
    const {i18n} = useTranslation();
    const user = useAppSelector(state => state.user).user;
    const [isLoaded, setIsLoaded] = useState(false);
    myTheme.direction = i18n.dir();
    document.body.dir = i18n.dir();

    useEffect(() => {
        setIsLoaded(true);
    }, []);


    useEffect(() => {
        const currentLanguage = convertLocaleToLanguage(i18n.language);
        if (user && user.locale !== currentLanguage) {
            i18n.changeLanguage(user.locale);
            myTheme.direction = i18n.dir();
            document.body.dir = i18n.dir();
        }
    }, [user, isLoaded]);

    return { myTheme } as const;
}
