import React, {useEffect, useState} from "react";
import Button from "@mui/joy/Button";
import {Stack} from "@mui/joy";
import {useTranslation} from "react-i18next";

interface ImagePickerProps {
    src: string | null;
    onFileChange?: (src: string | null) => void;
    style?: React.CSSProperties;
}

const ImagePicker: React.FC<ImagePickerProps> = ({ src, onFileChange, style }) => {
    const [logoPreview, setLogoPreview] = useState<string | null>(src);
    const {t} = useTranslation();

    useEffect(() => {
        setLogoPreview(src);
    }, [src]);

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement> | null = null) => {
        const file = e?.target.files ? e.target.files[0] : null;
        if (file) {
            const url = URL.createObjectURL(file);
            setLogoPreview(url);
            readFile(file);
        } else {
            setLogoPreview(null);
            onFileChange && onFileChange(null);
        }
    };
    const fileInputRef= React.useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const readFile = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onFileChange && onFileChange(reader.result as string);
        }
        reader.onerror = (error) => {
            console.log('Error: ', error);
        }
    }

    return (
        <>
            {!logoPreview && (
                <div style={style}>
                    <Button variant="outlined" sx={{width: "100%"}} onClick={handleButtonClick}>
                        {t("chooseImage")}
                    </Button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        onChange={handleLogoChange}
                        style={{ display: 'none' }}
                    />
                </div>
            )}
            {logoPreview && (
                <Stack spacing={2} alignItems="center">
                    <img src={logoPreview} alt="Preview" style={{maxWidth: '200px', maxHeight: '200px'}}/>
                    <Button color="danger" variant="outlined" sx={{width: "100%"}} onClick={() => handleLogoChange()}>{t("deleteImage")}</Button>
                </Stack>
            )}
        </>
    );
}

export default ImagePicker;
