import {Currency, formatPrice} from "../Models/Currency";

export const stringToDecimal = (value: string, currency: Currency | null = null): string => {
    let targetValue = value;
    if (targetValue.includes(".") && !targetValue.includes(",")) {
        targetValue = targetValue.replace(".", ",");
    }
    targetValue = targetValue.replace(/,(?=.*,)|[^\d,]/g, "");
    targetValue = targetValue.replace(",", ".");
    if (currency) {
        const number = Number(targetValue);
        targetValue = formatPrice(number, currency);
        targetValue = stringToDecimal(targetValue);
    }
    return targetValue;
}
