import {FC, useEffect, useState} from "react";
import {Modal, ModalClose, Sheet, Stack, Typography} from "@mui/joy";
import Button from "@mui/joy/Button";
import useMyTheme from "../Theme/useMyTheme";

interface Props {
    open: boolean;
    title: string;
    description?: string;
    buttonTitle?: string;
    onButtonClick?: () => void;
    onClose?: () => void;
}

const InfoModal: FC<Props> = ({open, title, description, buttonTitle, onButtonClick, onClose}) => {
    const [isOpen, setIsOpen] = useState<boolean>(open);

    const { myTheme } = useMyTheme();

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const close = () => {
        setIsOpen(false);
        onClose && onClose();
    }

    const handleButtonClick = () => {
        setIsOpen(false);
        onButtonClick && onButtonClick();
    }

    return (
        <Modal
            dir={myTheme.direction}
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={isOpen}
            onClose={close}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Sheet
                variant="outlined"
                sx={{
                    maxWidth: 500,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                }}
            >
                <ModalClose variant="plain" sx={{m: 1}}/>
                <Stack alignItems="center" spacing={1}>
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        px={4}
                    >
                        {title}
                    </Typography>
                    {description && (
                        <Typography id="modal-desc" textColor="text.tertiary" px={4}>
                            {description}
                        </Typography>
                    )}
                    {buttonTitle && (
                        <Button variant="soft" onClick={handleButtonClick}>
                            {buttonTitle}
                        </Button>
                    )}
                </Stack>
            </Sheet>
        </Modal>
    );
}

export default InfoModal;
